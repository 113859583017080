
:root {
  --body-large-font-size: 18px;
  --body-large-font-stack: #{$lyon-text-font-stack};
  --body-large-font-style: normal;
  --body-large-font-variant: normal;
  --body-large-font-weight: 400;
  --body-large-letter-spacing: .01em;
  --body-large-line-height: 1.388em;
  --body-large-margin-bottom: .5em;
  --body-large-margin-top: 1em;
}
