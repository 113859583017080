
.JCBPageLoader {
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  padding-left: var(--grid-page-padding);
  padding-right: var(--grid-page-padding);
  position: fixed;
  right: 0;
  top: 0;

  @include portrait {
    margin-top: -70px;
  }

  .JCBPageLoader__Icon {
    position: relative;

    svg {
      animation: jcb-pencil-loader 2s infinite;
      bottom: 0;
      position: absolute;
      top: 0;
    }
  }

  .JCBPageLoader__Title {
    @include typography(heading-2);

    margin-top: 140px;
    max-width: 320px;
    text-align: center;

    @include landscape {
      max-width: unset;
    }
  }

  @keyframes jcb-pencil-loader {
    0% {
      max-height: 0;
      opacity: 1;
    }

    65% {
      max-height: 120px;
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
}
