
.faq-list {
  padding-bottom: 50px;
  padding-top: 10px;
  position: relative;

  .faq-list__grid {
    @include grid-container;
  }

  .faq-list__wrapper {
    grid-column: column 1 / span 6;
  }

  .faq-list__title {
    @include typography(heading-2);

    margin-bottom: 1rem;
  }
}
