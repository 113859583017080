body {
  --content-with-sidebar-sidebar-width: 100vw;

  @include landscape {
    --content-with-sidebar-sidebar-width: 30vw;
  }
}

.content-with-sidebar {
  .content-with-sidebar__container {
    display: flex;
    flex-direction: row;
    position: relative;
    transform: translateX(0);
    transition: transform .3s ease-in-out;
  }

  .content-with-sidebar__main {
    position: relative;
    width: 100%;
    z-index: 1;
  }
}

.content-with-sidebar--sidebar-open {
  .content-with-sidebar__container {
    transform: translateX(var(--content-with-sidebar-sidebar-width));
  }
}

.content-with-sidebar-sidebar {
  background: $color-white;
  box-shadow: 3px 3px 10px 0 #0003;
  height: 100vh;
  left: calc(-1 * var(--content-with-sidebar-sidebar-width));
  margin-bottom: 20px;
  overflow: auto;
  padding: 16px 32px 32px 16px;
  position: fixed;
  top: 0;
  transform: translateX(0);
  transition: transform .3s ease-in-out;
  width: var(--content-with-sidebar-sidebar-width);

  .content-with-sidebar-sidebar__controls {
    margin-right: -16px;
    position: sticky;
    text-align: right;
    top: 0;
    z-index: 1;
  }
}

.content-with-sidebar-sidebar--open {
  transform: translateX(var(--content-with-sidebar-sidebar-width));
}
