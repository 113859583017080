
.picture {
  background-color: transparent;
  contain: content;
  display: block;
  position: relative;
  user-select: none;

  .picture__image {
    opacity: 0;
    transform: translate3d(0, 0, 0); // Edge bug
    transition: opacity $easing-change-slower;
    width: 100%;
  }
}

.picture--loaded {
  .picture__image {
    opacity: 1;
  }
}

.picture--background {
  z-index: 0;

  ~ * {
    color: $color-white;
    position: relative;
    z-index: 1;
  }
}

.picture--react {
  background-color: $color-black;
  contain: content;
  display: block;
  height: 100%;
  position: relative;
  user-select: none;
  width: 100%;

  source {
    object-fit: cover;
    object-position: 50% 50%;
  }

  .picture__image {
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    opacity: 1;
    transform: translate3d(0, 0, 0); // Edge bug
    transition: opacity $easing-change-slower;
    width: 100%;
  }
}
