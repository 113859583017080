
.ProjectAnnotationObject {
  @include grid-container;

  column-gap: 1.5rem;
  padding: 0 0 1.25rem;
  row-gap: 1.5rem;

  .ProjectAnnotationObject__Image {
    background-color: $color-brown-ultralight;
    grid-column: column 1 / span 3;
  }

  .ProjectAnnotationObject__Information {
    display: flex;
    flex-direction: column;
    grid-column: column 4 / span 6;
    justify-content: space-between;
  }

  .ProjectAnnotationObject__Information-subtitle,
  .ProjectAnnotationObject__Information-description {
    @include typography(body-small);
  }

  .ProjectAnnotationObject__Information-subtitle {
    margin-bottom: .25rem;
  }

  .ProjectAnnotationObject__Information-title {
    @include typography(heading-4);

    margin-bottom: .25rem;
    margin-top: 0;
  }

  .ProjectAnnotationObject__Information-annotations-public-amount,
  .ProjectAnnotationObject__Information-annotations-private-amount {
    @include typography(body-large-sans);

    margin-right: 1.5rem;

    svg {
      margin-right: .5rem;
      position: relative;
      top: 4px;
    }
  }

  .ProjectAnnotationObject__Annotations-collapse-btn {
    display: flex;
    padding-left: 0;
  }

  .ProjectAnnotationObject__Annotations-Wrapper {
    grid-column: column 4 / span 9;
  }

  .ProjectAnnotationObject__Annotations-List {
    counter-reset: listCounter;
    list-style-type: none;
    padding: 0;
  }

  .ProjectAnnotationObject__Annotations-ListItem {
    @include typography(body-large);

    display: flex;
    margin-bottom: 25px;

    &::before {
      content: counter(listCounter, decimal-leading-zero);
      counter-increment: listCounter;
      margin-right: 1.25rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .ProjectAnnotationObject__Annotations-ListItem-Content-Wrapper {
    column-gap: 20px;
    display: grid;
    flex-grow: 1;
    grid-template-columns: .35fr 1fr 60px 102px;
    justify-content: space-between;
  }

  .ProjectAnnotationObject__Annotations-ListItem-Thumbnail {
    @include typography(heading-7);

    .object-frame {
      background-color: $color-brown-ultralight;
    }
  }

  .ProjectAnnotationObject__Annotations-ListItem-Caption {
    b {
      font-weight: 600;
    }
  }
}
