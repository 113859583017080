
// NOTE: In the future, these translate() and rotate() and negative rotate() transforms could be possibly simplified by using ray() for polar coordinates? -EKL
.object-relationships {
  $ring-widths: (1: 30%, 2: 60%, 3: 100%, 4: 157.5%);
  $ring-positions: (
    1: (1: -18deg, 2: 54deg, 3: -72deg, 4: 18deg, 5: 36deg, 6: 72deg, 7: -54deg, 8: -36deg), // 18deg steps
    2: (1: -8deg, 2: 24deg, 3: 40deg, 4: -32deg, 5: 8deg, 6: -16deg, 7: -40deg, 8: 32deg, 9: -24deg, 10: 16deg), // 8deg steps
    3: (1: -12deg, 2: 12deg, 3: 20deg, 4: -20deg, 5: -4deg, 6: 4deg, 7: 28deg, 8: -28deg), // 8deg steps, -4deg
    4: (1: 2deg, 2: -6deg, 3: -18deg, 4: 10deg, 5: 18deg, 6: -2deg, 7: 6deg, 8: -10deg, 9: 14deg, 10: -14deg) // 4deg steps, -2deg
  );

  $mobile-from-top: true;
  $ring-transform: translate(-50%, -50%);
  $ball-transform: translate(50%, -50%);

  min-height: 1000px;
  position: relative;

  .object-relationships__wrapper {
    --sidebar-size: 10vh;

    color: $color-black;
  }

  .object-relationships__header {
    display: flex;
    flex-direction: column;
    gap: 8px;
    left: var(--grid-page-padding);
    position: absolute;
    top: 20px;

    h4 {
      @include typography(heading-4);

      margin-left: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 550px;
    }

    p {
      @include typography(heading-7);

      margin-left: 10px;
    }
  }

  .object-relationships__footer {
    bottom: 20px;
    left: var(--grid-page-padding);
    position: absolute;
  }

  .object-relationships__footer--info-button {
    background-color: $color-white;
  }

  .object-relationships__object {
    display: flex;
    height: var(--sidebar-size);
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0%;
    width: 100%;

    .picture {
      height: 100%;
    }

    img {
      height: 100%;
    }
  }

  .object-relationships__object-image {
    margin: auto;
    max-height: 100%;
    pointer-events: none;
    transform: translateY(50%);
  }

  .object-relationships__canvas {
    --canvas-rotation-degrees: #{if($mobile-from-top, 90deg, -90deg)};
    --ring-width-multiplier: 1.7;
    --ball-size: 7vw;

    border-top: var(--sidebar-size) solid transparent;
    flex: 1 1 100%;
    min-height: 100vh;
    overflow: hidden;
    position: relative;
  }

  .object-relationships__ring {
    --ring-width: 0%;

    aspect-ratio: 1;
    border: 1px dashed $color-brown-light;
    border-radius: 50%;
    height: auto;
    left: 50%;
    position: absolute;
    top: if($mobile-from-top, 0%, 100%);
    transform: $ring-transform rotate(var(--canvas-rotation-degrees, 0));
    width: var(--ring-width);
  }

  .object-relationships__positional-container {
    --ring-rotation-degrees: 0deg;

    aspect-ratio: 1;
    border-radius: 50%;
    height: auto;
    left: 50%;
    pointer-events: none;
    position: absolute;
    top: if($mobile-from-top, 0%, 100%);
    transform: $ring-transform rotate(calc(var(--canvas-rotation-degrees, 0) + var(--ring-rotation-degrees, 0)));
    width: var(--ring-width);
  }

  .object-relationships__ball-container {
    align-items: center;
    border-radius: 50%;
    display: flex;
    height: var(--ball-size);
    justify-content: center;
    position: absolute;
    right: 0;
    top: 50%;
    transform: $ball-transform rotate(calc((var(--canvas-rotation-degrees, 0) + var(--ring-rotation-degrees, 0)) * -1));
    transition: color $easing-change, background-color $easing-change, border-color $easing-change;
    width: var(--ball-size);
  }

  .object-relationships__ball-container--interactive {
    pointer-events: initial;

    &::before {
      border: 1px solid transparent;
      border-color: $color-pink;
      content: '';
      display: block;
      pointer-events: none;
      position: absolute;
      right: 50%;
      top: 50%;
      transform: rotate(calc(var(--canvas-rotation-degrees, 0) + var(--ring-rotation-degrees, 0)));
      transform-origin: center right;
      transition: border-color $easing-change;
      width: 100vw;
    }

    .link__icon {
      height: calc(var(--ball-size, 1rem) * .33);
      margin-left: 0;
      margin-right: 0;
      width: calc(var(--ball-size, 1rem) * .33);
    }

    &:hover,
    &:focus {
      .object-relationships__ball {
        background-color: $color-pink-light;
        border-color: $color-pink-light;
        color: $color-white;
      }

      &::before {
        border-color: $color-pink-light;
      }
    }
  }

  .object-relationships__ball-container--dashed {
    &::before {
      border-style: dashed;
    }
  }

  .object-relationships__ball-container--disabled {
    &::before {
      border-color: $color-brown-ultralight;
      border-width: .5px;
    }

    &::after {
      background: $color-white;
      border: 1px solid $color-brown-ultralight;
      border-radius: 50%;
      content: '';
      display: block;
      height: 8px;
      width: 8px;
      z-index: 1;
    }

    &:hover,
    &:focus {
      &::before {
        border-color: $color-brown-ultralight;
      }
    }
  }

  .object-relationships__ball {
    align-items: center;
    background: $color-white;
    border: 1px solid currentcolor;
    border-radius: 50%;
    color: $color-pink;
    cursor: pointer;
    display: flex;
    fill: $color-black;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 100%;

    &::before {
      // We try to cover the text and make it selectabl
      content: '';
      height: 40px;
      position: absolute;
      top: 67px;  // beautiful
      width: 200px;
    }

    .link__span {
      @include typography(field-label);

      color: $color-black;
      font-size: calc(var(--ball-size, 1rem) * .33);
    }
  }

  @each $level, $width in $ring-widths {
    .object-relationships__ring,
    .object-relationships__positional-container {
      &[data-level='#{$level}'] {
        --ring-width: calc(#{$width} * var(--ring-width-multiplier, 1));
      }
    }

    .object-relationships__positional-container {
      &[data-level='#{$level}'] {
        --ring-rotation-degrees: 180deg;

        @each $level2, $positions in $ring-positions {
          @if $level2 == $level {
            @each $position, $value in $positions {
              $negative-value: $value * -1;

              &[data-position='#{$position}'] {
                --ring-rotation-degrees: #{$value};
              }
            }
          }
        }
      }
    }
  }

  .object-relationships__ring-label {
    @include typography(body-small-sans);

    background-color: $color-grey-superlight;
    color: $color-black;
    display: block;
    padding: .25em 2.5em .35em;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
    transform: $ball-transform rotate(calc(var(--canvas-rotation-degrees) * -1)) translateY(-3rem);
    white-space: nowrap;
    width: auto;
  }

  .object-relationships__ball-text {
    @include typography(heading-5);

    font-size: calc(var(--ball-size, 1rem) * .33);
  }

  .object-relationships__ball-label {
    @include typography(heading-6);

    display: inline-flex;
    height: auto;
    justify-content: center;
    position: absolute;
    text-align: center;
    top: 100%;
    transform: translateY(50%);
    width: 25ch;
  }

  .object-relationships__ball-label--disabled {
    color: $color-brown-ultralight;
  }

  .object-relationships__ball-label-span {
    background-color: transparent;
    color: $color-black;
    display: inline-block;
    padding: .25rem;
    vertical-align: top;
  }

  @media (min-aspect-ratio: 1 / 1) {
    min-height: 700px;

    .object-relationships__wrapper {
      --sidebar-size: 10vw;
    }

    .object-relationships__object {
      height: 100%;
      width: var(--sidebar-size);
    }

    .object-relationships__object-image {
      transform: translateX(50%);
    }

    .object-relationships__ball {
      &::before {
        left: 0;
        top: auto;
      }
    }

    .object-relationships__canvas {
      --canvas-rotation-degrees: 0deg;
      --ring-width-multiplier: 1.1;
      --ball-size: 3vw;

      border-left: var(--sidebar-size) solid transparent;
      border-top-width: 0;
      height: 700px;
    }

    .object-relationships__ring,
    .object-relationships__positional-container {
      left: 0;
      top: 50%;
    }

    .object-relationships__ring-label {
      color: $color-brown-mid;
      transform: $ball-transform rotate(calc(var(--canvas-rotation-degrees) * -1));
    }

    .object-relationships__ball-label {
      justify-content: left;
      left: 110%;
      text-align: left;
      top: 50%;
      transform: translateY(-50%);
    }

    .object-relationships__ball-label--disabled {
      left: 63%;

      span {
        color: $color-brown-light;
      }
    }
  }
}
