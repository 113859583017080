
.file-field {
  margin: 2.5rem 0 0;
  position: relative;

  .file-field__label {
    @include typography(input);

    cursor: pointer;
    display: block;
    margin-bottom: .25rem;
    user-select: none;
  }

  .file-field__wrapper {
    font-size: 0;
    max-width: 100%;
    position: relative;
  }

  .file-field__input {
    float: left;
    height: 1px;
    left: 10px;
    opacity: 0;
    position: absolute;
    top: 10px;
    width: 1px;
    z-index: 0;

    &:disabled {
      + .button {
        opacity: .4;
        pointer-events: none;
      }
    }

    &:not(:disabled) {
      &:focus {
        + .button {
          border-color: color($color-black);
        }
      }
    }
  }

  .file-field__previews {
    font-size: 0;
    list-style: none;
    margin: calc((var(--grid-gutter) / 2) * (-1));
    padding: 0;

    + .file-field__wrapper {
      .button--clear {
        margin-top: calc(var(--grid-gutter) * 1.5);
      }
    }
  }

  .file-field__preview {
    display: inline-block;
    margin: calc(var(--grid-gutter) / 2);
    position: relative;
    width: calc(100% - var(--grid-gutter));

    &::after {
      background: rgba($color-white, .65);
      content: '';
      cursor: pointer;
      display: block;
      height: 100%;
      left: 0;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      top: 0;
      transition: opacity $easing-change;
      width: 100%;
      z-index: 1;
    }

    .button--remove {
      bottom: 15px;
      // left: 15px;
      margin: 0;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      transition: opacity $easing-change;
      z-index: 10;
    }

    &:hover,
    &:focus {
      &::after {
        pointer-events: auto;
      }

      &::after,
      .button--remove {
        opacity: 1;
      }
    }

    @media (hover: none) {
      .button--remove {
        opacity: 1;
        pointer-events: auto;
      }

      &::after {
        opacity: 0;
        pointer-events: none;
      }
    }
  }

  .file-field__preview-image-container {
    border: 1px solid color(light-grey);
    margin: 0 auto;
    padding: 0 0 100%;
    position: relative;
  }

  .file-field__preview-image {
    height: calc(100% - 30px);
    left: 15px;
    object-fit: contain;
    position: absolute;
    top: 15px;
    width: calc(100% - 30px);
  }

  .file-field__preview-filename,
  .file-field__preview-meta {
    @include typography(input, true, true);

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;

    span + span {
      margin-left: 10px;
    }
  }

  .file-field__preview-filesize {
    //font-style: italic;
  }

  .file-field__preview-resolution {
    font-weight: bold;
  }

  .button {
    margin: 0;

    + .button {
      margin-left: var(--grid-gutter);
    }
  }

  .button--clear {
    display: none;
  }

  &.file-field--has-files {
    .file-field__input {
      ~ .button--add {
        display: none;
      }
    }
  }

  &:not(.file-field--has-files) {
    .file-field__previews,
    .button--clear {
      display: none;
    }
  }

  //&.file-field--required {
  //  .file-field__label {
  //    &::after {
  //      content: '*';
  //      display: inline-block;
  //      margin-left: .25em;
  //    }
  //  }
  //}
  //
  //&:not(.file-field--required) {
  //  .file-field__label {
  //    &::after {
  //      content: '';
  //      display: inline-block;
  //      margin-left: .25em;
  //    }
  //  }
  //}

  &.file-field--error {
    .file-field__error {
      @include typography(caption);

      color: color(error);
      display: inline-block;
      max-width: 100%;
      width: auto;
    }

    .file-field__input {
      //border-color: color(error);
    }
  }

  @media (min-width: 530px) {
    .file-field__preview {
      width: calc(50% - var(--grid-gutter));
    }
  }
}
