
.article-introduction {
  grid-column: column 1 / span 12;
  margin-bottom: calc(var(--margin) * 4.5);
  padding-top: calc(var(--margin) * 3);

  @include portrait {
    margin-bottom: var(--block-margin);
    padding-top: var(--margin);
  }

  .article-introduction__grid-container {
    @include grid-container;
  }

  .article-introduction__title {
    grid-column: column 1 / span 12;
    margin-bottom: 5px;

    @include typography(heading-1);

    @include landscape {
      grid-column: column 1 / span 8;
    }
  }

  .article-introduction__text {
    grid-column: column 1 / span 12;

    @include mobile-only {
      margin-bottom: calc(var(--margin) * 2);
    }

    @include landscape {
      grid-column: column 1 / span 6;
    }
  }

  .article-introduction__tags-container {
    grid-column: column 1  / span 12;

    @include landscape {
      grid-column: column 9  / span 4;
      margin: -.5rem 0 0 -.5rem;
    }
  }

  .article-introduction__tag-title {
    @include typography(heading-7);

    display: block;

    @include landscape {
      margin-left: .5rem;
    }
  }

  .article-introduction__tag {
    margin-left: 0;
    margin-top: .5rem;

    @include landscape {
      margin-left: .125rem;
    }
  }
}

.article-introduction--primary {
  margin-bottom: var(--margin);
  padding-top: var(--header-padding);
}

.article-introduction--related {
  margin-bottom: 40px;

  .article-introduction__grid-container {
    grid-gap: 0;
  }
}

.article-introduction--secondary {
  .article-introduction__title {
    @include typography(heading-2);
  }

  .article-introduction__grid-container {
    grid-gap: 0;
  }
}
