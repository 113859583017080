
.SlideshowDeck {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 1;

  @include portrait {
    bottom: 25%;
    left: unset;
    max-width: 38vw;
    min-width: 38vw;
    right: 0;
  }

  @include landscape {
    max-width: 30vw;
    min-width: 30vw;
  }

  @include notebook {
    max-width: 25vw;
    min-width: 25vw;
  }

  @include desktop {
    max-width: 20vw;
    min-width: 20vw;
  }

  .SlideshowDeck__Wrapper {
    background-color: $color-white;
    position: relative;
  }

  .SlideshowDeck__Caption-Transition-Wrapper {
    &.SlideshowDeck__Caption-Transition-Wrapper-enter {
      opacity: 0;
    }

    &.SlideshowDeck__Caption-Transition-Wrapper-enter-active {
      opacity: 1;
    }

    &.SlideshowDeck__Caption-Transition-Wrapper-exit {
      opacity: 1;
    }

    &.SlideshowDeck__Caption-Transition-Wrapper-exit-active {
      opacity: 0;
    }

    &.SlideshowDeck__Caption-Transition-Wrapper-enter-active,
    &.SlideshowDeck__Caption-Transition-Wrapper-exit-active {
      transition: opacity 2s $bezier-fancy, transform 2s $bezier-fancy;
    }
  }

  .SlideshowDeck__Caption-Collapse-Wrapper {
    background-color: $color-white;
    opacity: 0;
    transition: opacity 200ms ease-in-out 300ms, padding 100ms linear 500ms;

    &.is-open {
      opacity: 1;
      padding: 24px 16px 16px;
      transition: unset;
    }
  }

  // Related to annotation window
  .SlideshowDeck__Caption-Wrapper,
  .SlideshowDeck__Details-Wrapper {
    background-color: $color-white;
    bottom: 0;
    position: relative;
    right: 0;
    z-index: 1;
  }

  .SlideshowDeck__Caption-Collapse {
    background-color: $color-white;
    transition: height .5s $bezier-fancy;
  }

  .SlideshowDeck__Caption-Title {
    @include typography(heading-4);
  }

  .SlideshowDeck__Caption-Subtitle {
    @include typography(body-large);

    font-size: .875rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .SlideshowDeck__Caption-Text {
    @include typography(body-small);
  }

  .SlideshowDeck__Details-Wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 1rem;
  }

  .SlideshowDeck__Detail-Title {
    margin-top: 0;
  }

  // Related to popup menu
  .SlideshowDeck__Menu-Collapse {
    bottom: 40px;
    left: 0;
    position: absolute;
    right: 0;
  }

  .SlideshowDeck__Menu {
    background-color: $color-grey-americana;
    color: $color-black;
    padding: 0;
    padding: .5rem 0;
    position: relative;
    z-index: 2;

    &.section-slide {
      background-color: transparent;
      color: $color-white;
    }
  }

  .SlideshowDeck__MenuItem {
    list-style-type: none;
    text-align: end;
  }

  .SlideshowDeck__MenuItemButton {
    padding: .6875rem;
  }

  // Related to actions and navigation
  .SlideshowDeck__Actions {
    display: flex;
    gap: .125rem;
    justify-content: flex-end;
  }

  .SlideshowDeck__Mobile-Object-Btn,
  .SlideshowDeck__Object-Btn {
    flex-grow: 1;

    .button__span {
      text-align: start;
    }
  }

  .SlideshowDeck__Mobile-Object-Btn {
    padding: .6875rem .625rem;
  }

  .SlideshowDeck__NavButtons {
    align-items: center;
    background-color: $color-yellow;
    display: inline-flex;
  }

  .SlideshowDeck__NavPosition {
    @include typography(body-small-sans);

    color: $color-black;
  }

  .SlideshowDeck__NavButton-Prev {
    transform: rotate(180deg);
  }
}
