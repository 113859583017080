
.search {
  border-bottom: 1px solid $color-white;
  margin: auto;
  max-width: 33.33%;

  .search__search-icon {
    display: inline-block;
    height: 13px;
    margin-right: 4px;
    position: relative;
    top: -2px;
    width: 13px;

    svg {
      height: 100%;
      width: 100%;
    }
  }

  .search__input {
    @include typography(search);

    background: none;
    display: inline-block;
    font-size: 24px;
    font-weight: 400;
    outline: none;
    padding: 4px;
    width: calc(100% - 23px);

    &::placeholder {
      color: $color-black;
      font-weight: 400;
      opacity: 1;
    }
  }
}
