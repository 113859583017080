@mixin base-link($color, $hover-color, $text-decoration: none, $hover-text-decoration: none) {
  color: $color;
  text-decoration: $text-decoration;

  &:hover {
    color: $color;
    text-decoration: $hover-text-decoration;
  }

  &:visited {
    color: $color;
  }
}
