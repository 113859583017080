
.feature-card {
  .picture-react {
    background-color: $color-black;
    contain: content;
    display: block;
    position: relative;
    user-select: none;

    source {
      object-fit: cover;
      object-position: 50% 50%;
    }

    .picture__image {
      // opacity: 0;
      transform: translate3d(0, 0, 0); // Edge bug
      transition: opacity $easing-change-slower;
      width: 100%;
    }
  }
}

.feature-card--dragged {
  cursor: grabbing;
  padding: calc(var(--grid-gutter) / 8);
  // padding-right: calc(var(--grid-gutter) / 2);
}
