@use 'sass:map';

$prevent-font-face-declarations: false !default;

// Variable font settings
$mono-font-name: 'IBM Plex Mono';
$mono-font-cleanname: 'ibmplexmono';
$mono-font-path: '/static/fonts/' + $mono-font-cleanname + '/';
$mono-font-stack: $mono-font-name, system-ui-monospace, monospace;
$mono-font-feature-settings: 'kern' 1, 'calt' 1, 'liga' 1, 'frac' 1, 'dlig' 0;

// Weight names and their real values
// Defined here, since these names can vary per font family. Foundries get up the the weirdest things sometimes.

// Reminder: Font style is not supposed to be selected by name (ie: 'black-italic'),
// this is done automatically by setting font-style italic. However, it's still in the
// list in order for the @font-face to work
$mono-font-weights: (
  regular: (weight: 400, style: normal),
  regular-italic: (weight: 400, style: italic),
  semibold: (weight: 600, style: normal),
  semibold-italic: (weight: 600, style: italic),
);

// Automatically map weights
@each $weight, $properties in $mono-font-weights {
  $font-style: map.get($properties, style);
  $font-weight: map.get($properties, weight);

  @if not ($prevent-font-face-declarations) {
    @font-face {
      font-display: swap;
      font-family: $mono-font-name;
      font-style: $font-style;
      font-weight: $font-weight;
      src: url($mono-font-path + $mono-font-cleanname + '-' + $weight + '.woff2') format('woff2');
    }
  }
}

// Font mixin whereby you can use the real font weights as displayed in Sketch, such as 'book' or 'black'.
// MKR: this seems to be unused in general?
@mixin font-plaax($font-weight: null) {
  // Set the basics
  font-family: $mono-font-stack;
  font-feature-settings: $mono-font-feature-settings;
  font-size-adjust: initial;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;

  @if ($font-weight) {
    font-weight: get-mono-weight($font-weight);
  }
}

// Given the weight name, get the real weight value. For example: 'black' returns 900.
@function get-mono-weight($font-weight) {
  // Convert possible 'normal' font-weight strings to 'regular', which is the font's actual weight name
  @if not $font-weight or $font-weight == 'normal' {
    $font-weight: 'regular';
  }

  // Look up the actual weight
  @if not (map.get($mono-font-weights, $font-weight)) {
    @error 'Variant "' + $font-weight + '" not found in "' + $mono-font-name + '" definition.';
  }

  $mapped-weight: map.get($mono-font-weights, $font-weight);
  $mapped-value-weight: map.get($mapped-weight, 'weight');

  @if ($mapped-value-weight) {
    $font-weight: $mapped-value-weight;
  }

  @return $font-weight;
}
