
.project-header {
  position: relative;

  @include padding-page-top;

  .project-header__wrapper {
    @include grid-container;
  }

  .project-header__title-container {
    grid-column: column 1 / span 7;
  }

  .project-header__title {
    display: inline-block;
    font-weight: bold;
    margin-right: 10px;
    text-transform: uppercase;
    vertical-align: middle;

    @include typography(heading-4);
  }

  .project-header__options-container {
    display: inline-block;
    vertical-align: middle;

    .icon-button {
      background-color: $color-grey-extralight;
      border-radius: 0;
      height: 40px;
      width: 40px;

      &:focus,
      &:hover {
        background: $color-white;
        color: $color-black;

        svg {
          fill: $color-black;
        }
      }
    }
  }

  .project-header__options {
    display: none;

    &.is-open {
      display: inline-block;
    }
  }

  .project-header__items-right {
    display: flex;
    grid-column: column 8 / span 5;

    .member-management {
      display: inline-block;
      margin-left: auto;

      .icon-button {
        background-color: $color-grey-extralight;
        border: 1px solid $color-grey-light;
        padding: 13px;

        &:focus,
        &:hover {
          svg {
            fill: $color-black;
          }
        }

        svg {
          height: 12px;
          width: 12px;
        }
      }
    }
  }
}
