
.video-embed {
  height: auto;
  position: relative;
  width: 100%;

  .video-embed__container {
    overflow: hidden;
    position: relative;
    width: 100%;

    .picture {
      height: auto;
      opacity: 1;
      width: 100%;

      &.picture--hide {
        opacity: 0;
      }
    }
  }

  .video-embed__caption {
    @include typography(body-small);

    color: $color-black;
    margin: 20px 0 0;
  }

  .video-embed__video,
  .video-embed__hit-target {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .video-embed__video {
    background-color: $color-black;
  }

  .video-embed__hit-target {
    cursor: pointer;
  }

  .video-embed__button {
    display: none;
    left: 50%;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);

    @include icon-button-sizing(18px, 18px);
  }

  .video-embed__iframe,
  iframe {
    height: 100%;
    left: 0;
    opacity: 1;
    position: absolute;
    top: 0;
    transition: opacity $easing-change-slower;
    width: 100%;
  }

  .video-embed__iframe--hidden {
    opacity: 0;
    pointer-events: none;
  }

  &.video-embed--playing {
    .video-embed__hit-target {
      display: none;
    }
  }

  &:not(.video-embed--playing) {
    .video-embed__video {
      filter: brightness(.66) contrast(1.1);
    }

    .video-embed__button--play {
      display: block;
    }
  }
}
