
.object-card {
  .object-card__title {
    @include typography(heading-2);

    color: $color-black;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  picture {
    align-items: flex-end;
    background: transparent;
    display: flex;
    height: 380px;

    img {
      max-height: 380px;
      width: auto !important;
    }
  }

  dl {
    display: grid;
    row-gap: 20px;
  }

  dt {
    @include typography(heading-5);

    margin-bottom: 6px;
    text-transform: uppercase;
  }

  dd {
    margin: 0;
  }

  p {
    @include typography(body-large);
  }
}
