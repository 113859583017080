.text-content {
  grid-column: column 1 / span 6;
  margin-bottom: 30px;
}

.text-content--sidebar {
  @include typography(body-small);

  margin: 12px 24px 16px 8px;
}
