
.feature-card {
  display: inline-block;
  width: 100%;
}

.feature-card__content {
  max-width: calc(100% * 6 / 7);
  padding-top: 1px;
}

.feature-card__title {
  @include typography(heading-2);

  color: $color-black;
  margin-top: 8px;

  a {
    color: $color-black;
  }
}

.feature-card__metadata-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;

  + .feature-card__subtitle {
    margin-top: 8px;
  }

  ~ .feature-card__title {
    margin-top: 8px;
  }
}

.feature-card__subtitle {
  @include typography(body-small);

  color: $color-black;
  display: block;
  margin-top: 8px;

  + .feature-card__title {
    margin-top: 8px;
  }
}

.feature-card__description {
  @include typography(body-small);

  margin-top: 4px;
}

.feature-card__metadata-item {
  display: inline-flex;
  margin-right: 20px;
}

.feature-card__metadata-icon {
  color: $color-black;
  display: inline-block;
  margin-right: .5rem;
}

.feature-card__metadata-value {
  @include typography(body-large-sans);

  color: $color-black;
  display: inline-block;
}

.feature-card__option-container {
  background-color: $color-white;
  left: 4px;
  position: absolute;
  top: 4px;
  transition: opacity $easing-change;
}

.feature-card__options-container {
  background: $color-grey-extralight;
  display: flex;
  flex-direction: row-reverse;
  left: 4px;
  position: absolute;
  top: 4px;
}

.feature-card__hidden-options-container {
  display: none;

  &.is-open {
    display: flex;
  }
}

.feature-card__options-btn {
  @include icon-button-sizing(16px, 16px);

  &:focus {
    opacity: 1;
  }
}

@include custom-variable('--feature-card-small-columns', 2, 5, 5, 5);

.feature-card--small {
  @include subgrid-container(
    $columns: 'repeat(var(--feature-card-small-columns), [column] minmax(0, 1fr))',
    $inline: true,
    $narrow: true
  );

  margin-bottom: 24px;
  width: 100%;

  .picture {
    display: inline-block;
    grid-column: span 1;
    margin-right: 0;

    @include landscape {
      grid-column: span 2;
    }
  }

  .feature-card__title {
    @include typography(heading-4);

    color: $color-black;
    display: inline-block;
    grid-column: span 1;
    margin-top: 0;
    vertical-align: top;
    word-wrap: break-word;

    @include landscape {
      grid-column: span 3;
    }
  }
}

.feature-card__relations {
  margin-top: 12px;
}

.feature-card--default {
  .feature-card__title {
    @include typography(heading-1);

    width: 100%;
  }
}

.feature-card--large {
  .feature-card__title {
    @include typography(heading-1);
  }

  .feature-card__description {
    @include typography(body-large);

    margin-bottom: calc(var(--grid-padding) * 3);
    margin-top: var(--grid-padding);

    @include landscape {
      margin-bottom: 0;
    }
  }
}

.feature-card--object {
  position: relative;

  .feature-card__title {
    @include typography(heading-4);

    margin: .25rem 0;
    width: 100%;
  }
}

.feature-card--options {
  position: relative;

  .feature-card__option-container,
  .feature-card__options-container {
    opacity: 0;
    transition: opacity $easing-change-slower;
  }

  .object-frame::after,
  picture::after {
    background-color: $color-black;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity $easing-change-slower;
    width: 100%;
  }

  &:focus,
  &:hover {
    .feature-card__option-container,
    .feature-card__options-container {
      opacity: 1;
    }

    .object-frame::after,
    .picture::after {
      opacity: .2;
    }
  }
}

.feature-card--related-object {
  .feature-card__title {
    margin-top: 6px;
    width: 100%;

    @include typography(heading-4);
  }
}

.feature-card--sortable {
  cursor: grab;

  @include landscape {
    grid-column: auto / span 3 !important;
    margin-left: 0;
    margin-right: 0;
    padding: calc(var(--grid-gutter) / 8);
    transition: box-shadow $easing-change-slower;

    .feature-card__option-container {
      left: calc(var(--grid-gutter) / 8);
      left: 0;
      top: calc(var(--grid-gutter) / 8);
      top: 0;
    }

    &:focus,
    &:hover {
      background-color: $color-white;
      box-shadow: 0 0 4px 2px rgb(40 0 0 / 5%);
    }

    // Accessibility
    &:focus,
    &:focus-visible {
      outline-color: $color-pink;
    }
  }
}

.feature-card--dragged {
  background-color: $color-white;
  box-shadow: 0 0 8px 2px rgb(40 0 0 / 15%);
}
