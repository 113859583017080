
.ProjectObjects {
  .ProjectObjects__Empty {
    margin-left: 25%;
    margin-right: 25%;
    text-align: center;
  }

  .ProjectObjects__Empty--Title {
    @include typography(heading-2);

    margin-bottom: .25rem;
  }

  .ProjectObjects__Empty--Paragraph {
    @include typography(body-large);

    margin-bottom: 1.5rem;
  }

  .ProjectObjects__AddSection {
    opacity: 0;
    position: relative;
    text-align: center;
    transition: opacity $easing-change;
    width: 100%;

    &.visible {
      opacity: 1;
    }

    &::before {
      border-bottom: 1px solid $color-brown-light;
      bottom: 40%;
      content: '';
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      transition: border $easing-change-extraslow;
      width: 100%;
      z-index: 0;
    }

    &:focus,
    &:hover {
      opacity: 1;
    }

    &.long-hover {
      &::before {
        border-color: $color-pink;
      }

      .ProjectObjects__AddSection-span {
        color: $color-black;
      }
    }

    &:focus,
    &:focus-visible {
      outline-color: $color-pink;
    }
  }

  .ProjectObjects__AddSection-span {
    @include typography(heading-7);

    background-color: $color-grey-americana;
    color: $color-brown-light;
    padding: 2px 12px;
    position: relative;
    transition: color $easing-change-extraslow;
    z-index: 1;
  }
}
