
.EndSlide {
  @include grid-container;

  background-color: $color-black;
  height: 100%;
  padding-bottom: 2rem;
  padding-top: 6rem;
  position: relative;
  z-index: 1;

  .EndSlide__Subtitle {
    @include typography(heading-6);

    margin-bottom: .5rem;
  }

  .EndSlide__Credits {
    color: $color-white;
    grid-column: column 1 / span 1;
    z-index: 1;

    @include portrait {
      grid-column: column 1 / span 6;
    }

    @include desktop {
      grid-column: column 2 / span 5;
    }
  }

  .EndSlide__Collapse {
    --mask-height-top: 48px !important;
    --mask-height-bottom: 48px !important;

    overflow: hidden;
  }

  .EndSlide__CuratorWrapper {
    margin-bottom: 2.25rem;
  }

  .EndSlide__Curator {
    @include typography(body-large);
  }

  .EndSlide__CollaboratorList {
    padding: 0;
  }

  .EndSlide__Collaborator {
    @include typography(body-large);

    list-style: none;
  }

  .EndSlide__Options {
    bottom: 0;
    display: flex;
    grid-column: column 2 / span 1;
    left: 0;
    position: fixed;

    @include portrait {
      flex-direction: column;
      gap: 1.5rem;
      grid-column: column 7/span 5;
      left: -50px;
      position: absolute;
      top: calc(50% - 48px);
    }
  }

  .EndSlide__Option {
    @include mobile-only {
      &:nth-child(2) {
        margin-left: .125rem !important;
      }
    }
  }

  .EndSlide__Collapse-Btn {
    color: $color-white;
  }

  .Collapse {
    &.masked-overflow {
      --scrollbar-color: transparent;
    }

    &:focus,
    :hover {
      .masked-overflow {
        --scrollbar-color: transparent;
      }
    }
  }
}
