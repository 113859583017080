
:root {
  --body-large-sans-font-size: 13px;
  --body-large-sans-font-stack: #{$open-sans-font-stack};
  --body-large-sans-font-style: normal;
  --body-large-sans-font-variant: normal;
  --body-large-sans-font-weight: 400;
  --body-large-sans-letter-spacing: .01em;
  --body-large-sans-line-height: 1.385em;
  --body-large-sans-margin-bottom: .5em;
  --body-large-sans-margin-top: 1em;
}
