
.NotFoundError {
  inset: 0 0 0 0;
  position: absolute;
  text-align: center;
  top: 25%;

  .NotFoundError__Title {
    @include typography(heading-2);

    margin-bottom: .25rem;
  }

  .NotFoundError__Paragraph {
    @include typography(body-large);

    margin-bottom: 1.5rem;
  }
}
