
.button {
  @include typography(button);

  align-items: center;
  border: 1px solid transparent;
  contain: content;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  max-width: min(100%, 520px);
  min-width: 60px;
  // outline: none; //TODO: Check if we want to remove outline, not good for accessibility
  overflow: hidden;
  padding: 11px 16px;
  position: relative;
  text-align: center;
  text-overflow: ellipsis;
  transition: color $easing-change, background-color $easing-change, border-color $easing-change;
  user-select: none;
  vertical-align: bottom;
  white-space: nowrap;
  width: auto;

  &:focus,
  &:focus-visible {
    outline-color: $color-pink;
  }
}

.button__span {
  display: block;
  flex: 1 1 100%;
  overflow: hidden;
  position: relative;
  text-align: center;
  text-overflow: ellipsis;
  top: 1px;
  white-space: nowrap;
  width: 100%;
}

.button__icon {
  display: block;
  height: var(--button-line-height);
  padding: .1em .1em .2em;
  transition: color $easing-change;
  width: var(--button-line-height);

  svg {
    height: 16px;
    width: 16px;
  }
}

.button__icon--before {
  margin-right: 1em;
}

.button__icon--after {
  margin-left: 1em;
}

.button:disabled,
.button--disabled {
  background-color: $color-white;
  border-color: $color-brown-light;
  color: $color-brown-light;
  cursor: default;
  pointer-events: none;
}

.button + .button {
  margin-left: 5px;
}

.button--primary {
  background-color: $color-pink;
  border-color: $color-pink;
  color: $color-black;

  &:hover:not(.button--disabled, :disabled, :active),
  &:focus:not(.button--disabled, :disabled, :active),
  &.button--selected {
    background-color: $color-pink-light;
    border-color: $color-pink-light;
  }

  &:active,
  &.button--active {
    background-color: $color-pink-light;
    border-color: $color-pink-light;
  }
}

.button--primary-dark {
  background-color: $color-grey-extradark;
  border-color: $color-grey-mediumdark;
  color: $color-white;

  &:hover:not(.button--disabled, :disabled),
  &:focus:not(.button--disabled, :disabled),
  &.button--selected {
    background-color: $color-white;
    border-color: $color-white;
    color: $color-grey-extradark;
  }
}

.button--secondary {
  background-color: $color-white;
  border-color: $color-pink;
  color: $color-black;

  &:hover:not(.button--disabled, :disabled),
  &:focus:not(.button--disabled, :disabled),
  &.button--selected {
    background-color: $color-pink-light;
    border-color: $color-pink-light;
  }
}

.button--tertiary {
  background-color: transparent;
  color: $color-black;

  &:hover:not(.button--disabled, :disabled),
  &:focus:not(.button--disabled, :disabled),
  &.button--selected {
    background-color: $color-pink-light;
    border-color: $color-pink-light;
  }
}

.button--primary-on-image {
  background-color: $color-white;
  border-color: $color-white;
  color: $color-black;

  &:hover:not(.button--disabled, :disabled),
  &:focus:not(.button--disabled, :disabled),
  &.button--selected {
    background-color: $color-grey-dark;
    border-color: $color-grey-dark;
    color: $color-grey-extradark;
  }
}

.button--secondary-on-image {
  background-color: $color-grey-dark;
  border-color: $color-grey-dark;
  color: $color-white;

  &:hover:not(.button--disabled, :disabled),
  &:focus:not(.button--disabled, :disabled),
  &.button--selected {
    background-color: $color-grey-dark;
    border-color: $color-grey-dark;
    color: $color-black;
  }
}

.button--tertiary-on-image {
  background-color: transparent;
  color: $color-white;

  &:hover:not(.button--disabled, :disabled),
  &:focus:not(.button--disabled, :disabled),
  &.button--selected {
    background-color: $color-pink-light;
    border-color: $color-pink-light;
    color: $color-black;
  }
}

.button--with-tip {
  background: none;
  border: 0;
  margin-right: 10px;
  padding: 15px 20px;

  .button__title,
  .button__tip {
    @include typography(link);

    pointer-events: none;
    text-transform: uppercase;
  }
}

.button--modal {
  padding-right: 0;

  .button__span {
    font-weight: 600;
  }

  .button__icon--after {
    align-items: center;
    display: flex;
    margin-right: -.25em;
  }
}

.button--clean {
  color: $color-black;
}

.button--collapse {
  border-bottom: 1px solid $color-pink;
  color: $color-black;
  padding-bottom: 2px;
  padding-left: 0;
  padding-right: 0;

  &:focus,
  &:hover {
    color: $color-pink !important;
  }

  .button__title {
    position: relative;
    top: 1px;
  }

  .button__icon {
    margin-left: .4em;
  }
}

.button--alert {
  background-color: $color-white;
  border: 1px solid $color-red;
  color: $color-red;

  &:hover:not(.button--disabled, :disabled),
  &:focus:not(.button--disabled, :disabled),
  &.button--selected {
    background-color: $color-red;
    color: $color-white;
  }
}

.button--exhibition {
  background-color: $color-yellow;
  color: $color-black;

  &:hover:not(.button--disabled, :disabled),
  &:focus:not(.button--disabled, :disabled),
  &.button--selected {
    .button__icon {
      color: $color-white;
    }
  }
}

.button--exhibition-clean {
  text-decoration: none;

  &:hover:not(.button--disabled, :disabled),
  &:focus:not(.button--disabled, :disabled),
  &.button--selected {
    .button__icon {
      color: $color-yellow;
    }
  }
}

.button--loading {
  animation: loading 2s infinite;
  animation-timing-function: ease-in-out;
  pointer-events: none;
  user-select: none;

  @keyframes loading {
    0% {
      opacity: 1;
    }

    50% {
      opacity: .5;
    }

    100% {
      opacity: 1;
    }
  }
}
