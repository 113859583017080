@use 'sass:math';

// Mixins

@mixin padding-page-top {
  padding-top: 130px;
}

@mixin project-image-gradient {
  background: linear-gradient(90deg, rgb(40 0 0 / 70%) 0%, rgb(40 0 0 / 0%) 125%);
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  @include portrait {
    background: linear-gradient(90deg, rgb(40 0 0 / 70%) 0%, rgb(40 0 0 / 0%) 100%);
  }
}

@mixin icon-button-sizing($svg-width:20px, $svg-height:20px, $border-width:1px) {
  padding: #{20px - $border-width - math.div($svg-height, 2)} #{20px - $border-width - math.div($svg-width, 2)};

  &.icon-button--big {
    padding: #{30px - $border-width - math.div($svg-height, 2)} #{30px - $border-width - math.div($svg-width, 2)};
  }

  .icon-button__icon {
    display: block;
    height: $svg-height;
    width: $svg-width;

    svg {
      height: $svg-height;
      width: $svg-width;
    }
  }
}
