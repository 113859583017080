
.article-content {
  margin-bottom: 3rem;
  margin-top: .5rem;
  position: relative;

  .article-content__grid {
    @include grid-container;
  }

  .article-content__wrapper {
    grid-column: var(--layout-content);
    margin-right: auto;
    max-width: 680px;
  }

  .article-content__buttons-wrapper {
    margin-top: 1.5rem;
  }

  .article-content__footer {
    margin-top: .5rem;
  }
}

.article-content--landing {
  .article-content__wrapper {
    @include landscape {
      grid-column: column 3 / span 7;
    }
  }
}

.article-content--top-space {
  // This is a bit random. Maybe move this into some variant if different space is needed somewhere else.
  padding-top: 80px;
}
