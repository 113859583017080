
.avatar {
  @include typography(heading-5);

  align-items: center;
  background: $color-brown-light;
  border-radius: 50%;
  display: flex;
  height: 40px;
  justify-content: center;
  overflow: hidden;
  width: 40px;

  img {
    border-radius: 50%; // Safari wants this?
  }
}
