
.richtext {
  contain: content;
  position: relative;
}

.richtext--regular {
  h1 {
    @include typography(heading-1, true, true);
  }

  h2 {
    @include typography(heading-2, true);

    margin-top: 1.5rem;
  }

  h3 {
    @include typography(heading-3, true);

    margin-top: 1.5rem;
  }

  h4 {
    @include typography(heading-4, true);

    margin-top: 1.5rem;
  }

  h5 {
    @include typography(heading-5, true);

    margin-top: 1.5rem;
  }

  h6 {
    @include typography(heading-6, true);

    margin-top: 1.5rem;
  }

  p {
    @include typography(body-large, true);

    &:not(:first-child) {
      margin-top: .5rem;
    }
  }

  ul,
  ol {
    @include typography(body-large, false);
  }

  a {
    &:not(.button) {
      &,
      &:link,
      &:active,
      &:visited {
        color: inherit;
        text-decoration: underline;
        text-decoration-color: $color-pink;
        text-underline-offset: -2px;
        text-underline-position: under;

        &:hover {
          color: $color-pink;
          // text-decoration-color: $color-pink-light;
        }
      }

      &:focus-visible {
        outline-color: $color-pink;
      }
    }
  }
}

.richtext--regular,
.richtext--simple {
  li + li {
    margin-top: .5rem;
  }

  ul {
    @include style-list-base;
    @include style-list-unordered;

    margin-top: .5rem;
  }

  ol {
    @include style-list-base;
    @include style-list-ordered;

    margin-top: .5rem;
  }
}

.richtext--introduction {
  p {
    @include typography(introduction, true, true);
  }
}

.richtext--landing {
  h2 {
    @include typography(heading-5, true, true);
  }

  p {
    @include typography(body-large, true, true);
  }
}

.richtext--small {
  p {
    @include typography(body-small, '', false, true);

    margin-top: .5rem;
  }

  h4 {
    @include typography(heading-7, '', true, false);

    &.header-featuring {
      margin-top: 1.4rem;
    }
  }

  .object-title {
    margin-top: 0;
  }
}
