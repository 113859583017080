
.number-button {
  @include typography(button);

  align-items: center;
  color: $color-black;
  cursor: pointer;
  display: inline-flex;
  height: 40px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  text-align: center;
  transition: background-color $easing-change, color $easing-change;
  user-select: none;
  vertical-align: bottom;
  white-space: nowrap;
  width: 40px;

  &.number-button--selected {
    background-color: $color-brown-ultralight;
  }

  &:hover,
  &:focus {
    background-color: $color-pink-light;
  }

  .number-button__icon {
    display: block;
    height: 16px;
    width: 16px;

    > svg {
      display: block;
      fill: currentcolor;
      height: 100%;
      width: 100%;
    }
  }
}

.number-button--previous,
.number-button--next {
  &:focus,
  &:hover {
    background-color: transparent;
    color: $color-pink;
  }

  &[disabled] {
    cursor: initial;
    opacity: .4;

    &:focus,
    &:hover {
      background-color: transparent;
      color: $color-black;
    }
  }
}
