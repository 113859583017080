
.ajax-modal {
  .modal__container {
    max-height: 80vh;
    max-width: 80vw;
    overflow: auto;
    position: relative;
  }

  .modal__header {
    align-items: center;
  }

  .modal__content {
    .richtext {
      margin-bottom: 20px;

      p {
        @include typography(body-large-sans);
      }
    }
  }

  .modal__close {
    margin: 0;
    right: 8px;
    top: 8px;
  }

  .ajax-modal__error-message {
    @include typography(input);

    color: $color-error;
    display: none;
    margin-bottom: 20px;
    padding: 0;
    width: 100%;
  }
}

.ajax-modal--error {
  .ajax-modal__error-message {
    display: block;
  }
}

.ajax-modal--full-screen {
  .modal__overlay {
    display: block;
  }

  .modal__container {
    height: 100%;
    max-height: 100vh;
    max-width: 100vw;
    overflow: auto;
    position: relative;
    width: 100%;
  }
}
