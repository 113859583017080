:root {
  --button-font-size: 10px;
  --button-font-style: normal;
  --button-font-stack: #{$open-sans-font-stack};
  --button-font-variant: normal;
  --button-font-weight: 600;
  --button-letter-spacing: .12em;
  --button-line-height: 1.6em;
  --button-margin-bottom: .5em;
  --button-margin-top: 1em;
  --button-text-transform: uppercase;
}
