
:root {
  --heading-1-font-size: 1.875rem;
  --heading-1-font-style: normal;
  --heading-1-font-stack: #{$lyon-display-font-stack};
  --heading-1-font-variant: normal;
  --heading-1-font-weight: 500;
  --heading-1-letter-spacing: .01em;
  --heading-1-line-height: 1.266em;
  --heading-1-margin-bottom: .25em;
  --heading-1-margin-top: 1em;
  --heading-1-word-spacing: normal;
}
