
.filter-list {
  margin-bottom: 24px;

  .filter-list__filters-container {
    overflow: hidden;
    transition: max-height .3s ease;
  }

  .filter-list__title {
    margin-bottom: 8px;

    @include typography(heading-6);
  }

  .filter-list__expand-button {
    @include typography(body-small-sans);

    display: none;
    font-weight: 600;
    margin-top: 8px;
    text-transform: capitalize;
  }

  .filter-list__filter-button {
    display: block;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &.filter-list--open {
    .filter-list__expand-button {
      .link__icon--after {
        transform: rotate(180deg);
      }
    }
  }
}
