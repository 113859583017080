
.link {
  @include typography(link);

  align-items: center;
  color: inherit;
  display: inline-flex;
  position: relative;
  text-decoration: none;
  transition: color $easing-change;

  .link__icon {
    display: inline-block;
    height: 16px;
    margin-left: .25rem;
    margin-right: .2em;
    transition: color, $easing-change, transform .3s ease;
    width: 16px;

    > svg {
      height: 100%;
      position: relative;
      top: 0;
      width: 100%;
    }
  }

  &:hover,
  &:focus {
    .link__span {
      text-decoration: none;
    }

    .link__icon {
      color: $color-pink;
    }
  }

  &:focus-visible {
    outline-color: $color-pink;
  }
}

.link--effect-hover-right {
  &:hover {
    .link__icon--after {
      transform: translateX(5px);
    }
  }
}

.link--h7 {
  @include typography(heading-7, true, true);

  cursor: pointer;

  &:hover {
    color: $color-pink;
  }
}

.link--anchor {
  @include typography(body-small);

  text-decoration: underline 1px solid $color-pink;
  text-underline-offset: 4px;

  &:hover {
    color: $color-pink;
  }
}

.link--tag {
  @include typography(body-small);

  background-color: $color-brown-ultralight;
  color: $color-black;
  cursor: pointer;
  display: inline-block;
  margin: 0 8px 8px 0;
  padding: 4px 8px;
  text-transform: capitalize;
  transition: background-color $easing-change;

  &:hover {
    background-color: $color-pink-light;
  }
}

.link--paragraph {
  @include typography(body-large, true, true);
}

.link--icon-spacing-big {
  .link__icon {
    height: 40px;
    margin: 0 0 0 -12px;
    padding: 12px;
    width: 40px;
  }
}
