
.modal {
  &:not(.react-modal) {
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s ease-in-out;

    &.is-open {
      opacity: 1;
      pointer-events: all;

      .modal__container {
        transform: scale(1);
      }
    }

    .modal__container {
      transform: scale(.5);
      transition: transform .3s ease-in-out;
    }
  }

  .modal__overlay {
    align-items: center;
    background-color: rgba($color-grey-extralight, .7);
    bottom: 0;
    display: grid;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 20000;
  }

  .modal__container {
    background-color: $color-white;
    box-shadow: 0 0 5px 0 rgb(0 0 0 / 10%);
    max-height: 100vh;
    min-width: 500px;
    overflow-y: auto;
  }

  .modal__wrapper {
    padding: 48px 32px 32px;
  }

  .modal__header {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    margin-bottom: .25rem;
    // margin-bottom: .75rem;
  }

  .modal__title {
    @include typography(heading-5);

    align-items: center;
    margin-bottom: 0;
    margin-top: 0;
    max-width: 426px;
    text-transform: uppercase;
  }

  .modal__close {
    background: transparent;
    border: 0;
    margin-left: 20px;
    position: absolute;
    right: 14px;
    top: 14px;

    svg {
      height: 16px;
      pointer-events: none;
      width: 16px;
    }
  }

  .modal__content {
    @include typography(body-large-sans);

    .link {
      @include typography(body-large-sans);

      .link__span {
        text-decoration: underline;
        text-underline-position: under;
      }

      &:hover,
      &:focus {
        .link__span {
          text-decoration-color: $color-pink;
        }
      }
    }
  }

  .modal__form-btn {
    display: flex;
    justify-content: flex-end;
  }

  .form-molecule__actions {
    text-align: end;
  }
}
