
.project-workspace {
  .ProjectWorkspace__Tabs {
    display: flex;
    margin: 24px var(--grid-page-padding);
  }

  .ProjectWorkspace__Tab {
    @include typography(heading-7);

    padding: 4px 12px;

    &:first-child {
      margin-right: 8px;
    }

    &.active {
      border-bottom: 3px solid $color-pink;
    }

    &[disabled] {
      border-width: 0;
      color: $color-brown-light;
    }

    &:focus,
    &:focus-visible {
      outline-color: $color-pink;
    }
  }

  .ProjectWorkspace__Tab-Content {
    margin-bottom: 6rem;
    margin-top: 2rem;
    padding: 0 var(--grid-page-padding);
  }

  .ProjectWorkspace__Tab-Transition {
    &.ProjectWorkspace__Tab-Transition-enter {
      .ProjectObjects,
      .ProjectAnnotations {
        opacity: 0;
      }
    }

    &.ProjectWorkspace__Tab-Transition-enter-active {
      .ProjectObjects,
      .ProjectAnnotations {
        opacity: 1;
      }
    }

    &.ProjectWorkspace__Tab-Transition-exit {
      .ProjectObjects,
      .ProjectAnnotations {
        opacity: 1;
      }
    }

    &.ProjectWorkspace__Tab-Transition-exit-active {
      .ProjectObjects,
      .ProjectAnnotations {
        opacity: 0;
      }
    }

    &.ProjectWorkspace__Tab-Transition-enter-active,
    &.ProjectWorkspace__Tab-Transition-exit-active {
      .ProjectObjects,
      .ProjectAnnotations {
        transition: opacity .35s ease-in-out, transform .35s ease-in-out;
      }
    }
  }
}
