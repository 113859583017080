
.file-button {
  height: 100%;
  position: relative;
  width: fit-content;

  .file-button__input {
    cursor: pointer;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.TabButton {
  @include typography(heading-7);

  border-bottom: 3px solid transparent;
  padding: 4px 1px;
  transition: color $easing-change, border $easing-change;

  &:not(:last-child) {
    margin-right: 1rem;
  }

  &.TabButton--active {
    border-color: $color-black;

    &:not(:disabled) {
      font-weight: 700;
    }
  }

  &[disabled] {
    border-width: 0;
    color: $color-brown-light;
  }

  &:focus:not(:disabled, :active, .TabButton--active),
  &:hover:not(:disabled, :active, .TabButton--active) {
    border-color: $color-pink;
  }

  &:focus,
  &:focus-visible {
    outline-color: $color-pink;
  }
}
