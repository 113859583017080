
.related-cards-carousel {
  .related-cards-carousel__grid {
    @include grid-container;
  }

  .related-cards-carousel__wrapper {
    grid-column: span 12;
  }

  .related-cards-carousel__title {
    @include typography(heading-7);
  }

  .swiper-container {
    margin-left: calc(var(--grid-gutter) * -.5);
    width: calc(100% + var(--grid-gutter));
  }

  .swiper-slide {
    padding: 0 calc(var(--grid-gutter) * .5);
  }

  .swiper-navigation {
    margin: 8px 0;
  }

  .swiper-button-next,
  .swiper-button-prev {
    @include icon-button-sizing(24px, 24px);
  }

  .swiper-button-prev {
    transform: rotate(180deg);
    transform-origin: center;
  }

  .swiper-button-disabled {
    opacity: .5;
    pointer-events: none;
  }

  .related-cards-carousel__result-card {
    .object-frame {
      padding-bottom: 300px;
    }
  }

  &.related-cards-carousel--as-footer {
    .related-cards-carousel__divider {
      border-color: $color-black;
      border-width: 1px;
      margin-bottom: 10px;
      margin-top: 80px;
    }
  }
}
