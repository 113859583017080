
.option-field {
  margin: 2.5rem 0 0;
  position: relative;

  &:focus-visible {
    outline: 1px solid $color-pink;
  }

  .option-field__label {
    @include typography(field-label, false, true, false);

    cursor: pointer;
    display: block;
    line-height: 32px;
    margin: 8px 0;
    user-select: none;
  }

  .option-field__wrapper {
    display: block;
    font-size: 0;
    max-width: 100%;
    position: relative;
  }

  .option-field__item {
    @include typography(body-large-sans, false, true);

    display: block;
  }

  .option-field__input {
    float: left;
    height: 0;
    margin-left: -150vw;
    width: 0;

    &:not(:disabled, :checked) {
      + .option-field__input-label:hover {
        .option-field__icon-background {
          background-color: $color-pink-light;
          color: $color-pink-light;
        }
      }
    }

    &:disabled {
      + .option-field__input-label {
        opacity: .4;
        pointer-events: none;
      }
    }

    &:checked {
      + .option-field__input-label {
        .option-field__icon-foreground {
          opacity: 1;
        }
      }
    }

    &:focus,
    &:focus-visible {
      outline-color: $color-pink;
    }
  }

  .option-field__input-label {
    @include typography(paragraph);

    align-items: flex-end;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    margin: 0;
    min-height: 18px;
    padding: 0 0 0 26px;
    position: relative;
    text-transform: none;
    user-select: none;
    width: 100%;

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .option-field__input-label-span {
    display: inline-block;
    vertical-align: baseline;

    @include typography(input);
  }

  .option-field__icon {
    color: $color-grey-medium;
    display: block;
    height: 18px;
    left: 0;
    opacity: 0;
    position: absolute;
    text-align: center;
    top: 0;
    transition: opacity $easing-change-faster, background-color $easing-change-faster, color $easing-change-faster;
    width: 18px;
  }

  .option-field__icon-background {
    background-color: $color-white;
    color: $color-pink;
    opacity: 1;
    transition: background-color $easing-change, color $easing-change;
  }

  .option-field__icon-foreground {
    opacity: 0;
  }

  &.option-field--required {
    .option-field__label {
      &::after {
        content: '*';
        display: inline-block;
        margin-left: .25em;
      }
    }
  }

  &:not(.option-field--required) {
    .option-field__label {
      &::after {
        content: '';
        display: inline-block;
        margin-left: .25em;
      }
    }
  }

  &.option-field--error {
    .option-field__error {
      @include typography(caption);

      color: $color-error;
      display: inline-block;
      max-width: 100%;
      width: auto;
    }

    .option-field__input {
      border-color: $color-error;
    }

    .option-field__icon-background {
      color: $color-error;
    }
  }

  &.option-field--inline {
    .option-field__item {
      display: inline-block;
      margin-right: 20px;
      width: auto;
    }
  }

  &.option-field--radio {
    .option-field__icon-background {
      border-radius: 50%;
      overflow: hidden;
    }
  }

  &.option-field--checkbox {
    //
  }
}
