
.exhibition-slideshow__mirador {
  header {
    display: none;
  }

  .mirador-canvas-nav {
    display: none;
  }

  .mosaic-root {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }

  .mosaic-tile {
    margin: 0;
  }
}
