
.IntroductionSlide {
  @include grid-container;

  background-color: $color-black;
  height: 100%;
  padding-bottom: 2rem;
  padding-top: 8rem;
  position: relative;
  z-index: 1;

  @include portrait {
    padding-bottom: 15%;
    padding-top: 15%;
  }

  .IntroductionSlide__Image {
    left: 0;
    position: absolute;
    top: 0;

    &::after {
      background: linear-gradient(90deg, rgb(40 0 0 / 70%) 0%, rgb(40 0 0 / 0%) 125%);
      content: '';
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;

      @include portrait {
        background: linear-gradient(90deg, rgb(40 0 0 / 70%) 0%, rgb(40 0 0 / 0%) 100%);
      }
    }
  }

  .IntroductionSlide__Wrapper {
    color: $color-white;
    grid-column: column 1 / span 7;
    z-index: 1;

    @include desktop {
      grid-column: column 2/ span 6;
    }
  }

  .IntroductionSlide__Credit {
    @include typography(heading-4);

    margin-bottom: 1.75rem;
    margin-top: .5rem;
  }

  .IntroductionSlide__Introduction {
    @include typography(body-large);
  }

  .IntroductionSlide__Collapse-Btn {
    color: $color-white;
  }

  .IntroductionSlide__Start-Btn {
    align-self: end;
    grid-column: column 1/span 2;
    width: fit-content;

    @include portrait {
      bottom: 25%;
      position: fixed;
      right: 20%;
    }
  }

  .Collapse.masked-overflow {
    --scrollbar-color: #fff;
  }
}
