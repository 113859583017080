
:root {
  --form-field-font-size: 20px;
  --form-field-font-style: normal;
  --form-field-font-stack: #{$mono-font-stack};
  --form-field-font-variant: normal;
  --form-field-font-weight: #{get-mono-weight(regular)};
  --form-field-letter-spacing: -.2px;
  --form-field-line-height: 30px;
  --form-field-margin-bottom: 15px;
  --form-field-margin-top: 0;
  --form-field-text-transform: normal;

  @include landscape {
    --form-field-font-size: 24px;
    --form-field-letter-spacing: -.28px;
    --form-field-line-height: 30px;
  }
}
