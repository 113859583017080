
.react-modal {
  .modal__container {
    max-height: 80vh;
    max-width: 33vw;
    overflow: auto;
    position: relative;
  }

  .modal__close {
    position: absolute;
    right: 8px;
    top: 8px;

    @include icon-button-sizing(16px, 16px);
  }

  .modal__content {
    .richtext {
      margin-bottom: 20px;

      p {
        @include typography(body-large-sans);
      }
    }
  }

  .modal__actions {
    align-items: center;
    display: flex;
    flex-direction: row-reverse;
    margin-top: 2rem;

    .button + .button {
      margin-right: 1rem;
    }
  }

  .react-modal__error-message {
    @include typography(input);

    background-color: $color-error;
    color: $color-white;
    display: none;
    padding: 2px 0 2px 20px;
    width: 100%;
  }
}

.react-modal--error {
  .react-modal__error-message {
    display: block;
  }
}

.react-modal-transition-enter {
  opacity: 0;

  .modal__overlay {
    background: transparent;
  }

  .modal__container {
    transform: scale(.8);
  }
}

.react-modal-transition-enter-active {
  opacity: 1;

  .modal__overlay {
    background-color: rgba($color-grey-extralight, .7);
    transition: background-color 300ms $bezier-fancy;
  }

  .modal__container {
    transform: scale(1);
    transition: transform 300ms $bezier-fancy;
  }
}

.react-modal-transition-exit {
  opacity: 1;

  .modal__container {
    transform: scale(1);
  }
}

.react-modal-transition-exit-active {
  opacity: 0;
  transition: opacity 300ms $bezier-fancy;

  .modal__container {
    transform: scale(.8);
    transition: transform 300ms $bezier-fancy;
  }
}
