@use 'sass:math';

.article-photo-carousel {
  padding-bottom: 40px;

  .article-photo-carousel__grid {
    @include grid-container;
  }

  .article-photo-carousel__wrapper {
    grid-column: span 11;
  }

  .article-photo-carousel__title {
    @include typography(heading-7);

    @include landscape {
      padding-left: get-grid-column-padding-offset($gap-count: 0, $column-start: 5);
    }
  }

  .swiper-container {
    overflow: visible;
    transform: translateX(#{calc((var(--grid-gutter) - var(--grid-margin)))});
  }

  .swiper-slide-image-frame {
    overflow: hidden;
    padding-bottom: 62.5%; // 5 / 8
    position: relative;
    width: 100%;

    figure {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .figure__caption {
    @include typography(body-small);

    color: $color-black;
    margin: var(--grid-padding) 0 0;
  }

  .swiper-slide-active {
    .figure__caption {
      margin-left: calc((var(--grid-gutter) + var(--grid-margin)));
    }
  }

  .swiper-navigation {
    margin: 10px 0;

    @include landscape {
      padding-left: get-grid-column-padding-offset($gap-count: 0, $column-start: 5);
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    @include icon-button-sizing(16px, 16px);
  }

  .swiper-button-prev {
    transform: rotate(180deg);
    transform-origin: center;
  }

  .swiper-button-disabled {
    opacity: .5;
    pointer-events: none;
  }
}
