
.combined-icon-button {
  @include typography(button);

  align-items: center;
  background-color: transparent;
  contain: content;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  max-width: min(100%, 520px);
  overflow: hidden;
  padding: 0;
  position: relative;
  text-align: start;
  text-overflow: ellipsis;
  transition: color $easing-change, background-color $easing-change, border-color $easing-change;
  user-select: none;
  white-space: nowrap;
  width: auto;

  &:focus,
  &:focus-visible {
    outline-color: $color-pink;
  }
}

.combined-icon-button__span {
  display: block;
  flex: 1 1 100%;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.combined-icon-button__icon {
  display: inline-block;
  height: 40px;
  padding: 11px;
  transition: background-color $easing-change, color $easing-change;
  width: 40px;

  svg {
    height: 16px;
    width: 16px;
  }
}

.combined-icon-button__icon--before {
  margin-right: .5em;
}

.combined-icon-button__icon--after {
  margin-left: .5em;
}

.combined-icon-button:disabled,
.combined-icon-button--disabled {
  cursor: default;
  opacity: .7;
  pointer-events: none;
}

.combined-icon-button--primary {
  color: $color-black;

  &:hover:not(.combined-icon-button--disabled, :disabled, :active),
  &:focus:not(.combined-icon-button--disabled, :disabled, :active),
  &.combined-icon-button--selected {
    .combined-icon-button__icon {
      background-color: $color-pink-light;
      border-color: $color-pink-light;
    }
  }

  &:active,
  &.combined-icon-button--active {
    .combined-icon-button__icon {
      background-color: $color-pink-light;
      border-color: $color-pink-light;
    }
  }
}

.combined-icon-button--primary-on-image {
  color: $color-white;

  &:hover:not(.combined-icon-button--disabled, :disabled),
  &:focus:not(.combined-icon-button--disabled, :disabled),
  &.combined-icon-button--selected {
    .combined-icon-button__icon {
      background-color: $color-pink-light;
      border-color: $color-pink-light;
      color: $color-black;
    }
  }
}

.combined-icon-button--clean {
  color: $color-black;
}

.combined-icon-button--exhibition {
  color: $color-yellow;

  .combined-icon-button__span {
    margin: 0 .5rem;
  }

  .combined-icon-button__icon {
    background-color: $color-yellow;
    color: $color-black;
  }

  &:hover:not(.combined-icon-button--disabled, :disabled),
  &:focus:not(.combined-icon-button--disabled, :disabled),
  &.combined-icon-button--selected {
    color: $color-white;

    .combined-icon-button__icon {
      color: $color-white;
    }
  }
}
