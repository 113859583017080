:root {
  --link-font-size: 10px;
  --link-font-stack: #{$open-sans-font-stack};
  --link-font-style: normal;
  --link-font-variant: normal;
  --link-font-weight: 600;
  --link-letter-spacing: 12%;
  --link-line-height: 1.17em;
  --link-margin-bottom: .5em;
  --link-margin-top: 1em;
  --link-word-spacing: normal;
}
