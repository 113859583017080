
.ProjectAnnotationSection {
  &:not(:last-child) {
    margin-bottom: 3.75rem;
  }

  .ProjectAnnotationSection__title {
    @include typography(heading-2);

    grid-column: column 1 / span 7;
    margin-bottom: 1.5rem;
    max-width: 50vw;
    word-break: break-word;
  }
}
