
.tooltip {
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: opacity ease 200ms;
  z-index: 100000; // Object detail page needs at least 10,000

  .tooltip__wrapper {
    background-color: $color-grey-light;
    max-width: 290px;
    opacity: 1;
    padding: 5px 8px;
    transition: transform ease 100ms;
    visibility: visible;
  }

  .tooltip__arrow {
    height: 0;
    position: absolute;
    top: 50%;
    width: 0;
  }

  .tooltip__container {
    //
  }

  .tooltip__text {
    @include typography(input);
  }
}

.tooltip--left {
  .tooltip__wrapper {
    transform: translate(calc(-100% - 20px), -50%);
  }

  .tooltip__arrow {
    border-bottom: 7px solid transparent;
    border-left: 7px solid $color-grey-light;
    border-top: 7px solid transparent;
    right: -7px;
  }
}

.tooltip--right {
  .tooltip__wrapper {
    transform: translate(20px, -50%);
  }

  .tooltip__arrow {
    border-bottom: 7px solid transparent;
    border-right: 7px solid $color-grey-light;
    border-top: 7px solid transparent;
    left: -7px;
  }
}

.tooltip--active {
  opacity: 1;
}
