
.mirador {
  --spacing: calc(var(--grid-gutter) + var(--grid-margin));

  background-color: $color-grey-americana;
  height: var(--real-100vh, 100vh);
  position: relative;
  transition: background-color $easing-change;

  &.dark-mode {
    background: $color-grey-extradark;
    position: relative;
    z-index: 100;

    main.mirador-viewer,
    section.MuiPaper-root {
      background: $color-grey-extradark;
    }

    .mirador__menu-btn {
      background: $color-grey-extradark;
      border-color: $color-grey-medium;
      color: $color-white;

      &.active {
        background: $color-white;
        border-color: $color-white;
        color: $color-black;
      }
    }
  }

  &.image-tools-is-open {
    .mirador__nav,
    .mirador__title-panel,
    .mirador__btns {
      display: none;
    }
  }

  &.annotation-panel-is-open {
    .mirador__nav {
      bottom: 10px;
      left: 368px;
    }

    .mirador__title-panel,
    .mirador__btns {
      transform: translateY(200%);
    }
  }

  .mirador__viewer {
    --offset: 6px;

    bottom: 120px;
    left: calc(var(--spacing) - var(--offset));
    position: absolute;
    right: calc(var(--spacing) - var(--offset));
    top: calc(100px - var(--offset));

    header {
      // hide mirador window top bar
      display: none;
    }

    .mirador-canvas-nav {
      // hide navigation as we have our own component
      display: none;
    }

    // overwrite background of viewer, resource list and gallery view
    main.mirador-viewer,
    section.MuiPaper-root {
      background-color: $color-grey-americana;
      transition: background-color $easing-change;
    }

    // overwrite styling of annotation creaton panel
    aside.MuiPaper-root,
    aside .MuiPaper-root,
    .mirador-companion-window-header {
      background: $color-grey-light;
    }

    aside.MuiPaper-root {
      border-left: 0;
      padding: 10px 20px;
    }

    div.mosaic-tile {
      box-shadow: none;
    }

    div.openseadragon-canvas:focus {
      outline: none;
    }

    // overwrite button styling
    .MuiButton-root {
      background: transparent;
      border-radius: 0;
      box-shadow: none;
      text-transform: none;

      &:active,
      &:focus,
      &:hover {
        background: transparent;
        box-shadow: none;
      }
    }

    .MuiButton-containedPrimary {
      border: 1px solid $color-grey-medium;

      &:focus,
      &:hover {
        border-color: $color-teal;
      }
    }

    // Override MuiChip for annotation icon in gallery view
    .MuiChip-root {
      background-color: 'rgba(255, 255, 255, 0.9)';
      border-radius: 0;
      color: $color-green;
      font-weight: 600;

      .MuiChip-avatar {
        color: $color-green;
      }

      .MuiChip-label {
        font-weight: 600;
      }
    }
  }

  .mirador__menu {
    @include typography(body-large-sans);

    display: none;
    left: var(--spacing);
    position: fixed;
    top: 100px;
    z-index: $z-index-mirador-menu;

    @include landscape {
      display: initial;
    }
  }

  .mirador__menu-btn-group {
    display: flex;
    flex-direction: column;

    .mirador__menu-btn {
      &:focus,
      &:hover {
        z-index: 1;
      }
    }

    > :not(:first-child) {
      margin-top: -1px;
    }
  }

  .mirador__menu-btn {
    align-items: center;
    background: $color-white;
    border: 1px solid $color-pink;
    display: flex;
    height: 40px;
    justify-content: center;
    width: 40px;

    &:focus,
    &:hover {
      background-color: $color-pink-light;
      border-color: $color-pink-light;
    }

    svg {
      height: 16px;
      width: 16px;
    }
  }

  .mirador__menu-more-btns-wrapper {
    display: flex;
    flex-direction: row;
    max-width: 0;
    overflow: hidden;
    transition: max-width $easing-change;

    .icon-button {
      background-color: $color-white;
    }
  }

  .mirador__menu-more-btns-container {
    display: flex;
    flex-direction: row;
    margin-top: -1px;

    &.is-open {
      .mirador__menu-more-btns-wrapper {
        max-width: 999px;
      }

      > .mirador__menu-btn {
        background-color: $color-brown-ultralight;
        border-color: $color-brown-ultralight;
        border-top-color: $color-pink;
      }
    }
  }

  .mirador__annotations-panel {
    background: $color-white;
    bottom: 0;
    left: 0;
    padding: 4rem 1.25rem 1.5rem;
    position: fixed;
    top: 0;
    transform: translateX(-100%);
    transition: all $easing-change-slower;
    width: 346px;
    z-index: 2;

    &.is-open {
      box-shadow: 3px 0 20px 0 #c8c8c88a;
      transform: translateX(0);
    }

    .mirador__annotations-panel-wrapper {
      height: 100%;
      overflow: auto;

      &.no-annotations {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      // Scrollbar styling
      // width
      &::-webkit-scrollbar {
        width: .25rem;
      }

      // Track
      &::-webkit-scrollbar-track {
        background: transparent;
      }

      // Handle
      &::-webkit-scrollbar-thumb {
        background: $color-black;
      }

      // Handle on hover
      &::-webkit-scrollbar-thumb:hover {
        background: $color-brown-light;
      }
    }

    .mirador__annotations-panel__close-btn {
      position: absolute;
      right: .5rem;
      top: .5rem;
    }

    .mirador__annotations-panel__project-selector-wrapper {
      margin-bottom: 3.75rem;
    }

    .mirador__annotations-panel__project-selector-label {
      @include typography(heading-7);

      margin-bottom: .25rem;
    }

    .mirador__annotations-panel__title {
      @include typography(heading-4);

      margin-top: 38px;
      max-width: 90%;
    }

    .mirador__annotations-panel__suspense {
      // margin-top: 4rem;
    }

    .mirador__annotations-panel__annotation-count {
      align-items: center;
      display: flex;
      margin-bottom: 24px;
    }

    .mirador__annotations-panel__add-btn {
      bottom: 1.5rem;
      left: 1.25rem;
      position: absolute;
      z-index: 2;
    }

    .mirador__annotations-panel__skeleton {
      margin-bottom: 1rem;
    }

    .JCBErrorMessage {
      margin-bottom: 1rem;
      max-width: 90%;
    }

    .mirador__annotations-panel__loading-overlay {
      align-items: center;
      background-color: rgba($color-white, .9);
      display: flex;
      inset: 0 0 0 0;
      justify-content: center;
      padding: 0 1rem;
      position: absolute;
      z-index: 2;
    }

    .mirador__annotations-panel__annotation-empty-wrapper {
      margin-bottom: 8.3rem;
      text-align: left;
    }

    .mirador__annotations-panel__annotation-empty-description {
      @include typography(body-small);

      margin-bottom: 1rem;
    }

    &.no-annotations {
      .mirador__annotations-panel__add-btn {
        position: initial;
      }
    }

    ol {
      counter-reset: listCounter;
      display: grid;
      gap: 1rem;
      list-style-type: none;
      padding: 0;
      position: relative;

      li {
        display: flex;
        flex-wrap: wrap;

        &:hover {
          &::before {
            background: transparent;
            border-color: $color-pink;
          }
        }

        &::before {
          @include typography(heading-6);

          border-bottom: 3px solid transparent;
          content: counter(listCounter, decimal-leading-zero);
          counter-increment: listCounter;
          display: grid;
          height: 26px;
          place-items: center;
          transition: border-color $easing-change;
          width: 26px;
        }
      }

      > :last-child {
        margin-bottom: 4rem;
      }
    }

    .mirador__annotations-panel__annotation-content {
      padding-right: 48px;
      width: 100%;

      b {
        font-weight: 600;
      }
    }

    .mirador__annotations-panel__annotation-actions {
      display: flex;
      padding-top: 0;

      // Places the expanding menu buttons behind the button--options button
      > span {
        order: 1;
      }

      .button--options {
        order: 0;

        &:focus,
        &:hover {
          color: $color-pink;
        }
      }

      button {
        padding: 4px;
        transition: background-color $easing-change, border-color $easing-change;

        &:not(.button--options):hover {
          background-color: $color-pink-light;
        }
      }
    }
  }

  .mirador__title-panel {
    @include typography(heading-2);

    background: transparent;
    border: 1px solid transparent;
    left: var(--spacing);
    padding: 8px 24px 12px 48px;
    position: absolute;
    text-align: left;
    top: calc(var(--real-100vh, 100vh) - 60px);
    transition: all $easing-change-slower;

    .link__icon {
      background-color: $color-pink;
      border: 1px solid $color-pink;
      color: $color-black;
      display: grid;
      height: 40px;
      left: 0;
      margin-left: 0;
      margin-right: 0;
      place-items: center;
      position: absolute;
      top: 0;
      transition: background-color $easing-change-slower, border-color $easing-change-slower;
      width: 40px;

      svg {
        height: 47.5%;
        width: 47.5%;
      }
    }

    @include landscape {
      width: calc(50% - var(--spacing));
    }

    &:focus,
    &:hover {
      .icon {
        background-color: $color-pink-light;
        border-color: $color-pink-light;
        color: $color-black;
      }
    }
  }

  .mirador__btns {
    align-items: center;
    bottom: 10px;
    display: none;
    position: absolute;
    right: var(--spacing);
    transition: all $easing-change-slower;

    @include landscape {
      display: flex;
    }
  }

  .mirador__project-selector {
    align-items: center;
    display: inline-flex;
  }

  .mirador__project-selector-label {
    @include typography(heading-7);

    margin-right: .75rem;
  }

  .mirador__object-info-overlay {
    background: $color-grey-americana;
    bottom: 0;
    left: 0;
    overflow: scroll;
    padding-bottom: 60px;
    position: fixed;
    right: 0;
    top: 0;
    transform: translateY(100%);
    transition: all $easing-change-slower;
    z-index: $z-index-object-overlay;

    &.is-open {
      transform: translateY(0);
    }
  }

  .mirador__nav {
    @include typography(body-small-sans);

    align-items: center;
    bottom: 70px;
    display: flex;
    justify-content: space-between;
    left: var(--spacing);
    position: absolute;
    right: var(--spacing);
    transition: all $easing-change-slower;

    @include portrait {
      justify-content: flex-end;
    }
  }

  .mirador__nav-btns-wrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
  }

  .mirador__nav-btns {
    display: grid;
    height: 40px;
    place-items: center;
    width: 40px;

    &:hover:not(:disabled) {
      background: $color-pink-light;
    }

    &:disabled {
      color: $color-grey-medium;
    }

    svg {
      height: 50%;
      width: 50%;
    }

    &.mirador__nav-btns--next {
      svg {
        transform: rotate(90deg);
      }

      margin-left: 4px;
      margin-right: 15px;
    }

    &.mirador__nav-btns--prev {
      margin-right: 4px;

      svg {
        transform: rotate(-90deg);
      }

      @include portrait {
        margin-left: 4px;
      }
    }
  }

  .mirador__nav-view-wrapper {
    display: flex;
    flex-direction: row;
  }

  .mirador__view-btns {
    display: grid;
    height: 40px;
    place-items: center;
    transition: border-color $easing-change;
    width: 40px;

    &:hover:not(:disabled) {
      // border: 1px solid $color-pink;
      background: $color-pink-light;
    }

    &:disabled {
      background: $color-brown-ultralight;
      // border: 1px solid $color-pink-light;
    }

    svg {
      height: 30%;
      width: 30%;
    }
  }

  .mirador__range-slider {
    appearance: none;
    background: transparent;
    cursor: pointer;
    display: none;
    flex-grow: 1;
    height: 20px;

    &:focus {
      outline: none; // TODO: add our own focus styling
    }

    @mixin mirador-range-slider-track {
      background: $color-black;
      border: 0;
      height: 1px;
    }

    @mixin mirador-range-slider-thumb {
      appearance: none;
      background: $color-black;
      border: 1px solid $color-grey-extralight;
      border-radius: 50%;
      height: 11px;
      margin-top: -5px;
      width: 11px;
    }

    // For some reason the selectors need to be seprate ¯\_(ツ)_/¯

    &::-webkit-slider-runnable-track {
      @include mirador-range-slider-track;
    }

    &::-moz-range-track {
      @include mirador-range-slider-track;
    }

    &::-webkit-slider-thumb {
      @include mirador-range-slider-thumb;
    }

    &::-moz-range-thumb {
      @include mirador-range-slider-thumb;
    }

    @include portrait {
      display: inherit;
    }
  }

  .mirador__image-tools {
    left: 40px;
    overflow: hidden;
    position: absolute;
    top: 120px;

    &.is-open {
      .mirador__image-tools-container {
        transform: translateX(0%);
      }
    }
  }

  .mirador__image-tools-container {
    align-items: flex-start;
    display: flex;
    transform: translateX(-100%);
    transition: all $easing-change-slower;
  }

  .mirador__image-tool {
    display: flex;
    flex-direction: column;
    width: 40px;

    &:focus,
    &:hover,
    &.is-active {
      .mirador__image-tool-btn {
        background: $color-white;
      }
    }
  }

  .mirador__image-tool-btn {
    background: rgba($color-white, .8);
    display: grid;
    height: 40px;
    place-items: center;
    width: 40px;
  }

  .mirador__image-tool-slider {
    background: rgba($color-white, .8);
    height: 118px;

    input {
      appearance: none;
      background: transparent;
      border: 1px solid $color-grey-medium;
      cursor: pointer;
      height: 20px;
      left: 10px;
      margin: 0;
      padding: 0;
      position: relative;
      top: 14px;
      transform: rotate(-90deg) translate(-100%);
      transform-origin: top left;
      width: 90px;

      &:focus {
        outline: none; // TODO: add our own focus styling
      }

      @mixin mirador-image-tool-slider-track {
        background: $color-black;
        border: 0;
        height: 1px;
      }

      @mixin mirador-image-tool-slider-thumb {
        appearance: none;
        background: $color-black;
        border: 0 solid transparent;
        border-radius: 50%;
        height: 7px;
        margin-top: -3px;
        width: 7px;
      }

      &::-webkit-slider-runnable-track {
        @include mirador-image-tool-slider-track;
      }

      &::-moz-range-track {
        @include mirador-image-tool-slider-track;
      }

      &::-webkit-slider-thumb {
        @include mirador-image-tool-slider-thumb;
      }

      &::-moz-range-thumb {
        @include mirador-image-tool-slider-thumb;
      }
    }
  }

  .mirador__add-project-modal {
    .modal__container {
      bottom: 60px;
      position: absolute;
      right: var(--spacing);
    }
  }
}
