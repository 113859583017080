
.notification {
  --type-color: #{$color-info};

  background-color: $color-grey-americana;
  border-left: 3px solid var(--type-color);
  padding: 12px 36px;
  position: relative;

  .notification__icon {
    color: var(--type-color);
    left: 9px;
    position: absolute;
    top: 12px;
  }

  .notification__title {
    @include typography(heading-6);

    margin-top: 0;
  }

  .notification__message {
    @include typography(body-small-sans);
  }

  &.notification--success {
    --type-color: #{$color-green};
  }

  &.notification--warning {
    --type-color: #{$color-yellow};
  }

  &.notification--error {
    --type-color: #{$color-error};
  }
}
