:root {
  --heading-6-font-size: 10px;
  --heading-6-font-stack: #{$open-sans-font-stack};
  --heading-6-font-style: normal;
  --heading-6-font-variant: normal;
  --heading-6-font-weight: 700;
  --heading-6-letter-spacing: .1em;
  --heading-6-line-height: 1.17em;
  --heading-6-margin-bottom: .5em;
  --heading-6-margin-top: 1em;
  --heading-6-word-spacing: normal;
  --heading-6-text-transform: uppercase;
}
