:root {
  --heading-7-font-size: 10px;
  --heading-7-font-stack: #{$open-sans-font-stack};
  --heading-7-font-style: normal;
  --heading-7-font-variant: normal;
  --heading-7-font-weight: 600;
  --heading-7-letter-spacing: .12em;
  --heading-7-line-height: 1.6em;
  --heading-7-margin-bottom: .5em;
  --heading-7-margin-top: 1em;
  --heading-7-word-spacing: normal;
  --heading-7-text-transform: uppercase;
}
