:root {
  --input-font-size: 13px;
  --input-font-stack: #{$open-sans-font-stack};
  --input-font-style: normal;
  --input-font-variant: normal;
  --input-font-weight: 400;
  --input-letter-spacing: 0;
  --input-line-height: 1.385em;
  --input-margin-bottom: .5em;
  --input-margin-top: 1em;
}
