
@include custom-variable('--card-mosaic-small-cards-columns', 4, 10, 10, 10);

.card-mosaic {
  margin: var(--block-margin) 0;

  .card-mosaic__grid-container {
    @include grid-container;

    grid-gap: 0 var(--grid-gutter);
  }

  .card-mosaic__title-wrapper {
    grid-column: var(--layout-content);
    margin-bottom: 1rem;
    max-width: 680px;
    order: 0;
  }

  .card-mosaic__title {
    @include typography(heading-5);

    margin-bottom: .25rem;
  }

  .card-mosaic__description {
    @include typography(body-small);
  }

  .feature-card__description {
    @include mobile-only {
      margin-bottom: .75rem;
    }
  }

  .card-mosaic__featured-card-wrapper {
    grid-column: column 1 / span 12;
    order: 1;

    @include mobile-only {
      .feature-card__picture {
        margin: 0 calc(0px - var(--grid-page-padding));
      }
    }

    @include landscape {
      grid-column: column 1 / span 7;
    }
  }

  .card-mosaic__credit {
    @include typography(heading-7);

    grid-column: column 1 / span 12;
    margin-bottom: calc(var(--grid-padding) * 2);
    order: 2;

    &.card-mosaic__credit-featured {
      grid-column: unset;
    }

    @include mobile-only {
      margin-bottom: 2.5rem;
    }

    @include landscape {
      grid-column: column 3 / span 10;
      margin-bottom: 0;
      margin-top: calc(var(--grid-padding) * 2);
      order: 3;
    }
  }

  .card-mosaic__cards-small-container {
    grid-column: column 1 / span 12;
    order: 3;

    @include landscape {
      grid-column: column 8 / span 5;
      order: 2;
    }

    .button {
      margin-top: 1rem;

      @include landscape {
        margin-top: 1.5rem;
      }
    }
  }

  @include landscape {
    .card-mosaic__title-wrapper {
      grid-column: column 3 / span 7;
    }
  }
}

.card-mosaic--centered {
  .card-mosaic__featured-card-wrapper {
    @include landscape {
      grid-column: column 3 / span 7;
      margin-bottom: 3rem;
    }
  }

  .card-mosaic__cards-small-container {
    display: flex;
    flex-flow: row wrap;

    @include landscape {
      grid-column: column 3 / span 10;
    }
  }

  .card-mosaic__featured-small {
    width: 100%;

    @include portrait {
      margin-bottom: 1.5rem;
      width: 50%;

      picture {
        grid-column: span 2;
      }

      .feature-card__title {
        grid-column: span 3;
        max-width: 90%;
      }

      &:nth-child(odd) {
        padding-right: 2rem;
      }

      &:nth-child(even) {
        padding-left: 2rem;
      }
    }
  }

  .button {
    grid-column: column 1 / span 12;
    margin-top: 1rem;
    order: 3;
    width: fit-content;

    @include landscape {
      grid-column: 3 / span 10;
      margin-top: 1.5rem;
    }
  }
}
