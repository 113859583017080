
@include custom-variable('--search-results-grid-columns', 2, 6, 8, 12);
@include custom-variable('--search-results-grid-spacing', 60px, 60px, 60px, 60px);

.search-results {
  background: $color-grey-americana;
  min-height: 100vh;
  overflow: hidden;
  padding-bottom: var(--block-margin);
  padding-top: 140px;

  .search-results__grid-container {
    @include grid-container;

    transition: padding-left .3s ease;
  }

  .search-results__result {
    @include quick-layout((auto, 2), (auto, 6), (auto, 4), (auto, 4));

    margin-bottom: 2rem;
  }

  &.search-results--open-sidebar {
    .search-results__grid-container {
      @include grid-container('repeat(var(--search-results-grid-columns), [column] minmax(0, 1fr))');

      padding-left: calc(380px + var(--grid-gutter));
    }

    .search-results__sidebar {
      box-shadow: 3px 0 20px 0 #c8c8c88a;
      transform: translateX(0);

      .results-text {
        margin-bottom: 24px;
      }

      .option-field--checkbox {
        margin-bottom: 24px;
        margin-top: 0;
      }
    }
  }

  &.search-results--sidebar-closing {
    .search-results__grid-container {
      @include grid-container('repeat(var(--search-results-grid-columns), [column] minmax(0, 1fr))');
    }

    .search-results__sidebar {
      transform: translateX(-100%);
    }
  }

  &.search-results--sidebar-opening {
    .search-results__grid-container {
      @include grid-container;

      padding-left: calc(380px + var(--grid-gutter));
    }

    .search-results__sidebar {
      transform: translateX(0);
    }
  }

  .search-results__sidebar {
    background: $color-white;
    max-height: 100%;
    min-height: 100%;
    overflow-y: auto;
    padding: 50px 20px 0 30px;
    position: fixed;
    top: 0;
    transform: translateX(-100%);
    transition: transform .3s ease;
    width: 380px;
    z-index: 200;

    // Scrollbar styling
    // width
    &::-webkit-scrollbar {
      width: .25rem;
    }

    // Track
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    // Handle
    &::-webkit-scrollbar-thumb {
      background: $color-black;
    }

    // Handle on hover
    &::-webkit-scrollbar-thumb:hover {
      background: $color-brown-light;
    }

    .search-results__sidebar-close {
      position: absolute;
      right: 4px;
      top: 4px;

      @include icon-button-sizing(16px, 16px);
    }

    .search-results__search {
      margin-bottom: 32px;

      .search__input {
        @include typography(button);
      }
    }
  }
}
