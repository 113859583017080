
.styleguide-typography {
  margin: 200px 64px var(--block-margin);
  position: relative;

  .styleguide-typography__grid {
    @include grid-container;
  }

  .styleguide-typography__wrapper {
    grid-column: column 1 / span 4;
    padding: 0 var(--grid-spacing);

    &:not(:first-child) {
      margin-block-start: var(--block-margin);
    }
  }

  .styleguide-typography__heading-1 {
    @include typography(heading-1);
  }

  .styleguide-typography__heading-2 {
    @include typography(heading-2);
  }

  .styleguide-typography__heading-3 {
    @include typography(heading-3);
  }

  .styleguide-typography__heading-4 {
    @include typography(heading-4);
  }

  .styleguide-typography__heading-5 {
    @include typography(heading-5);
  }

  .styleguide-typography__heading-6 {
    @include typography(heading-6);
  }

  .styleguide-typography__heading-7 {
    @include typography(heading-7);
  }

  @include portrait {
    .styleguide-typography__wrapper {
      grid-column: column 1 / span 6;
    }
  }

  @include landscape {
    .styleguide-typography__wrapper {
      grid-column: column 2 / span 7;
    }
  }

  @include notebook {
    .styleguide-typography__wrapper {
      grid-column: column 3 / span 6;
    }
  }

  @include desktop {
    .styleguide-typography__wrapper {
      grid-column: column 3 / span 6;
    }
  }
}
