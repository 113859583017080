
.results-text {
  @include typography(heading-5);

  margin-bottom: 40px;

  .search-value__text {
    display: block;
  }
}
