:root {
  --body-small-font-size: 14px;
  --body-small-font-stack: #{$lyon-text-font-stack};
  --body-small-font-style: normal;
  --body-small-font-variant: normal;
  --body-small-font-weight: 400;
  --body-small-letter-spacing: .01;
  --body-small-line-height: 19px;
  --body-small-margin-bottom: .5em;
  --body-small-margin-top: 1em;
}
