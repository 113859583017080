.section--black {
  background-color: $color-black;

  h1,
  p,
  h4,
  a {
    color: $color-white;
  }
}

.section--40 {
  flex-grow: 1;

  > div:not(:first-child) {
    margin-top: 40px;
  }

  > div {
    margin-bottom: 40px;
  }

  > div:last-child {
    margin-bottom: 0;
    padding-bottom: 40px;
  }
}
