
.JCBErrorMessage {
  @include typography(body-large-sans);

  color: $color-error;
  display: flex;
  gap: 1rem;
  margin-top: 1rem;

  &.JCBErrorMessage--nomargin {
    margin-top: 0;
  }
}
