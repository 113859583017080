// DEPRECATED IN FAVOR OF tag.scss

.filter-button {
  border: 1px solid $color-pink;
  padding: 2px 8px;
  transition: background-color $easing-change-slower, border $easing-change-slower;

  &:hover,
  &.filter-button--active {
    background-color: $color-pink-light;
    border: 1px solid $color-pink-light;
  }

  .filter-button__span {
    @include typography(body-small);

    color: $color-black;
    pointer-events: none;
    text-transform: capitalize;
  }

  &.filter-button--link {
    display: inline-block;
  }
}

.filter-button-radio {
  display: block;
  font-size: 0;
  max-width: 100%;
  position: relative;

  label {
    @include typography(body-small);

    border: 1px solid $color-pink;
    cursor: pointer;
    display: inline-block;
    margin: 0;
    padding: 4px 8px 2px;
    user-select: none;
  }

  input {
    float: left;
    height: 0;
    margin-left: -150vw;
    width: 0;

    &:not(:disabled, :checked) {
      + label:hover {
        background-color: $color-pink-light;
        border: 1px solid $color-pink-light;
      }
    }

    &:disabled {
      + label {
        opacity: .4;
        pointer-events: none;
      }
    }

    &:checked {
      + label {
        background-color: $color-pink-light;
        border: 1px solid $color-pink-light;
      }
    }
  }
}
