
.menu-bar {
  @include grid-container;

  align-items: center;
  display: flex;
  flex-direction: row;
  height: 90px;
  justify-items: stretch;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 104;
}

.menu-bar__layer-items-left,
.menu-bar__layer-items-right {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.menu-bar__layer-items-left {
  justify-content: flex-start;
  order: 1;
}

.menu-bar__layer-items-right {
  justify-content: flex-end;
  order: 3;
  position: relative;
}

.menu-bar__logo {
  color: $color-black;
  left: calc(50% - 115px);
  position: absolute;
  top: calc(50% - 12px);
  width: 230px;
  z-index: 105;
}

.menu-bar__back {
  @include typography(heading-7);

  padding-left: 11px;

  .button__icon--before {
    margin-right: .5em;
  }

  .button__span {
    position: relative;
    top: 1px;
  }

  &:hover {
    .link__icon--after {
      transform: unset;
    }
  }

  @include mobile-only {
    .button__span {
      display: none;
    }
  }
}

.menu-bar__search {
  height: 40px;
  margin-right: 20px;
  position: relative;

  @include mobile-only {
    .combined-icon-button__span {
      display: none;
    }
  }
}

.menu-bar__search-link {
  margin-right: 1rem;

  @include icon-button-sizing(16px, 16px);
}

.menu-bar__user-info {
  display: none;

  @include landscape {
    display: initial;
  }
}

.button--workspace,
.button--login,
.button--join {
  display: none;

  @include landscape {
    display: inline-flex;
  }
}

.button--join {
  margin-right: 1.25rem;
}

// Logged in menu starts here
.button--workspace {
  margin-left: .75rem;
  transition: background-color $easing-change, color $easing-change;

  &:focus,
  &:hover {
    background-color: $color-pink-light;
  }
}

.button--menu {
  margin-left: .5rem;
}

.menu-bar--secondary,
.menu-bar--public-project {
  ::selection {
    background: rgba($color-pink-light, .5);
  }

  &::before {
    // background: rgba(40, 0, 0, .7);
    background: linear-gradient(180deg, rgb(40 0 0 / 50%) 0%, rgb(40 0 0 / 0%) 75%);
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  color: $color-white;

  .menu-bar__logo {
    color: $color-white;
  }

  .menu-bar__back {
    .link__icon--after,
    .link__icon--before {
      path,
      circle {
        stroke: $color-white;
      }
    }
  }

  // For React part. BE should use correct button variant.
  .menu-bar__search-link,
  .menu-bar__back,
  .button--menu,
  .button--workspace {
    color: $color-white;

    &:focus,
    &:hover {
      color: $color-black;
    }
  }
}

.menu-bar--public-project {
  .menu-bar__layer-items-left {
    left: -16px;
    position: relative;
  }
}

.menu-bar--annotation {
  background-color: transparent;
  z-index: 0;

  .menu-bar__logo {
    display: none;
  }

  .menu-bar__layer-items-left {
    display: none;
  }

  .menu-bar__layer-items-right {
    display: none;
  }
}

.menu-bar--minimal {
  background-color: transparent;

  .menu-bar__logo {
    color: $color-white;
  }

  .menu-bar__layer-items-left {
    display: none;
  }

  .menu-bar__layer-items-right {
    display: none;
  }
}

.menu-bar--project {
  &::before {
    @include mobile-only {
      content: none;
    }
  }

  .menu-bar__layer-items-left {
    display: none;

    @include portrait {
      display: flex;
      left: -12px;
      position: relative;
    }
  }

  .button--workspace {
    display: none;

    @include portrait {
      display: flex;
    }
  }
}
