
.ProjectHeader {
  min-height: 33vh;
  padding: 8.5rem var(--grid-page-padding) 1.5rem;
  position: relative;
  width: 100%;

  .ProjectHeader__ImageWrapper {
    background-color: $color-brown-ultralight;
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .ProjectHeader__ImageButton {
    left: -12px;
    margin-bottom: .5rem;
    padding: 12px;
  }

  .ProjectHeader__ImageUpload-Modal {
    &.ProjectHeader__ImageUpload-Modal--text {
      margin-bottom: 1rem;
    }

    &.ProjectHeader__ImageUpload-Modal--Dropzone {
      align-items: center;
      background-color: $color-brown-ultralight;
      cursor: pointer;
      display: flex;
      height: 250px;
      justify-content: center;
      position: relative;
    }

    &.ProjectHeader__ImageUpload-Modal--preview-wrapper {
      align-items: center;
      background-color: $color-white;
      display: flex;
      height: 100%;
      justify-content: center;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;

      &::after {
        background-color: $color-black;
        content: '';
        display: block;
        height: 100%;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        transition: opacity $easing-change-slower;
        width: 100%;
        z-index: 1;
      }

      .button {
        opacity: 0;
        transition: opacity $easing-change-slower;
        z-index: 2;
      }

      &:focus,
      &:hover {
        &::after {
          opacity: .2;
        }

        .button {
          opacity: 1;
        }
      }
    }

    &.ProjectHeader__ImageUpload-Modal--preview {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: auto;

      .picture__image {
        height: 100%;
      }
    }
  }

  .ProjectHeader__IntroButton {
    margin-top: 1rem;
  }

  .ProjectHeader__Title {
    @include typography(heading-1);

    width: 50%;
  }

  .ProjectHeader__IntroWrapper {
    margin-top: 8px;
    width: 50%;
  }

  .ProjectHeader__Intro {
    @include typography(body-large);
  }

  .ProjectHeader__Right {
    bottom: 1.5rem;
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    position: absolute;
    right: var(--grid-page-padding);
  }

  &.ProjectHeader--has-image {
    ::selection {
      background: rgba($color-pink-light, .5);
    }

    .Collapse.masked-overflow {
      --scrollbar-color: #{$color-white};
    }

    .ProjectHeader__ImageWrapper::after {
      @include project-image-gradient;
    }

    .ProjectHeader__ImageButton {
      color: $color-white;
    }

    .EditableTextarea__Text-placeholder {
      color: rgba($color-white, .65);
    }

    .ProjectHeader__IntroButton {
      color: $color-white;
    }
  }
}
