.mobile-notification {
  background-color: $color-brown-ultralight;
  bottom: 0;
  left: 0;
  opacity: 1;
  padding: 3.5rem 1.25rem 3rem;
  position: fixed;
  right: 0;
  z-index: 1;

  @include portrait {
    display: none;
  }

  &.closing {
    opacity: 0;
    transition: opacity $easing-change-slower;
  }

  &.closed {
    display: none;
  }

  .mobile-notification__title {
    @include typography(heading-3);
  }

  .mobile-notification__text {
    @include typography(body-large);
  }

  .mobile-notification__close-btn {
    position: absolute;
    right: 1.25rem;
    top: 1rem;
  }
}
