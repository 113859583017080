
:root {
  --search-font-size: 24px;
  --search-font-stack: #{$mono-font-stack};
  --search-font-style: normal;
  --search-font-variant: normal;
  --search-font-weight: #{get-mono-weight(semibold)};
  --search-letter-spacing: -.01em;
  --search-line-height: 32px;
  --search-margin-bottom: .5em;
  --search-margin-top: 1em;
  --search-word-spacing: normal;
}
