
.projects-overview {
  padding: 30px 0 50px;
  position: relative;

  .projects-overview__container {
    @include grid-container;
  }

  .projects-overview__header {
    align-items: center;
    display: flex;
    grid-column: span 12;
    justify-content: space-between;
    margin-bottom: 5px;
  }

  .projects-overview__title {
    @include typography(heading-4);

    font-weight: bold;
    text-transform: uppercase;
  }

  .projects-overview__card {
    grid-column: auto / span 4;
    margin-bottom: 40px;
  }
}
