
.ProjectSection {
  margin-bottom: 3rem;
  margin-top: .75rem;
  position: relative;

  &:focus,
  &:hover {
    .ProjectSection__Actions {
      opacity: 1;
    }
  }

  .ProjectSection__Actions {
    display: flex;
    height: 20%;
    justify-content: flex-end;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity $easing-change;
    width: 100%;

    &::before {
      border-top: 1px solid $color-brown-light;
      content: '';
      left: 0;
      position: absolute;
      top: 20px;
      width: calc(100% - 120px);
      z-index: 0;
    }

    &.visible {
      opacity: 1;
    }
  }

  .ProjectSection__Action-Btn {
    z-index: 1;

    // Move up btn
    &:nth-child(2) svg {
      transform: rotate(-90deg);
    }

    // Move down btn
    &:nth-child(3) svg {
      transform: rotate(90deg);
    }
  }

  .ProjectSection__Title {
    @include typography(heading-2);

    background-color: $color-grey-americana;
    max-width: 50vw;
    padding-right: 1rem;
    padding-top: .375rem;
    width: fit-content;
    word-break: break-word;

    .EditableTextField__Text {
      transition: color $easing-change;

      &:focus,
      &:hover {
        color: $color-brown-light;
      }
    }
  }

  .ProjectSection__Description {
    @include typography(body-large);

    margin-top: .5rem;
    width: 50%;
  }

  .feature-card-grid__grid-container {
    column-gap: 0;
    padding-left: 0;
    padding-right: 0;

    > div {
      // grid-column: auto / span 3;
      // margin-left: calc(var(--grid-gutter) / 2);
      // margin-right: calc(var(--grid-gutter) / 2);
    }
  }
}

.ProjectSection__DeleteConfirmModal {
  .modal__content {
    // max-width: 85%;
  }
}
