
.floating-menu {
  position: absolute;
  transition: opacity $easing-change;

  &:not(.floating-menu--active) {
    opacity: 0;
    pointer-events: none;
  }
}
