@use 'sass:math';

.picture-header {
  background: $color-grey-medium;
  position: relative;

  &:not(.picture-header--content) {
    // min-height: math.div(2, 3) * 100vw;
    min-height: 408px;

    @include notebook {
      // min-height: math.div(2, 5) * 100vw;
      min-height: 408px;
    }

    // @include desktop {
    //   min-height: math.div(4, 10) * 100vw;
    // }
  }

  .picture-header__picture {
    height: 100%;
    position: absolute;
    width: 100%;

    img {
      height: 100%;
    }
  }

  &.picture-header--content {
    .picture-header__picture::after {
      @include project-image-gradient;
    }
  }

  .picture-header__header-container {
    padding: 8.5rem var(--grid-page-padding) 3.5rem;
  }

  .picture-header__title {
    @include typography(heading-1);

    @include portrait {
      max-width: 75%;
    }

    @include landscape {
      max-width: 50%;
    }

    @include desktop {
      max-width: 40%;
    }
  }

  .picture-header__introduction {
    @include typography(body-large);

    margin-bottom: 5px;

    @include portrait {
      max-width: 75%;
    }

    @include landscape {
      max-width: 50%;
    }

    @include desktop {
      max-width: 40%;
    }
  }

  .picture-header__anchor-button {
    left: -16px;
    margin-bottom: .5rem;

    .button__icon {
      margin-left: .4em;
    }
  }

  .Collapse.masked-overflow {
    --scrollbar-color: #{$color-white};
  }

  .Collapse__toggle-btn {
    color: $color-white;
  }
}
