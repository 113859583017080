
.EditableTextField {
  display: block;
  position: relative;
  text-align: start;

  &:focus,
  &:focus-visible {
    outline-color: $color-pink;
  }

  .EditableTextField__Text {
    color: $color-black;
    cursor: text;
    transition: color $easing-change;

    &:focus,
    &:hover {
      color: $color-brown-light;

      .EditableTextField__Icon {
        opacity: 1;
      }
    }
  }

  .EditableTextField__Input-Wrapper {
    position: relative;
  }

  .EditableTextField__Ghost-input {
    max-width: 50vw;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    user-select: 0;
    white-space: pre;
  }

  .EditableTextField__Input {
    background-color: transparent;
    border-bottom: 1px solid $color-black;
    width: 100%;

    &:focus,
    &:focus-visible {
      outline: none;
    }
  }

  .EditableTextField__Icon {
    margin-left: .5rem;
    opacity: 0;
    transition: opacity $easing-change;
  }
}

.EditableTextField--contrast {
  .EditableTextField__Text {
    color: $color-white;

    &:focus,
    &:hover {
      color: rgba($color-white, $alpha: .6);
    }
  }

  .EditableTextField__Input {
    border-color: rgba($color-white, .6);
    color: $color-white;
  }
}
