
.article-carousel {
  grid-column: column 1 / span 12;
  margin-left: -24px;
  margin-top: 60px;
  position: relative;
  z-index: -1;

  .article-carousel__grid-container {
    @include grid-container;
  }

  .article-carousel__swiper {
    grid-column: column 1 / span 12;
    max-width: 100%;
    overflow: visible; // contrary to typical swiper-container
    width: 100%;
  }

  .article-carousel__navigation {
    bottom: 0;
    padding-bottom: 24px;
    position: sticky;
    z-index: $z-index-modal-stack + 1;
  }

  .article-carousel__navigation-grid {
    @include grid-container;
  }

  .article-carousel__navigation-buttons {
    grid-column: column 1 / span 12;
    text-align: right;
  }
}

.article-carousel-slide {
  .article-carousel-slide__body {
    height: 100%;
    padding: 0 24px;
    position: relative;

    &::before {
      background: $color-brown-ultralight;
      // This is a seemingly working attempt to make a full-height background color hover state for a slide item.
      // The advantage of box shadow is that it does not alter the parent container space.
      bottom: calc(100% + 50vh);
      box-shadow: 0 249px 0 200vh $color-brown-ultralight;
      clip-path: polygon(0 100%, 100% 100%, 100% 300%, 0 300%);
      content: '';
      display: none;
      height: 200vh;
      left: 0;
      position: absolute;
      width: 100%;
      z-index: -1;
    }
  }

  .article-carousel-slide__current-label {
    @include typography(heading-6);

    color: $color-brown-mid;
    position: absolute;
    transform: translateY(calc(-100% - 7px));
  }

  .swiper-button-disabled {
    display: none;
  }
}

.article-carousel-slide--current {
  .article-carousel-slide__body {
    &::before,
    &::after {
      display: block;
    }
  }
}
