
.project-creators {
  border-top: 1px solid $color-brown-light;
  margin: 0 var(--grid-page-padding) var(--grid-page-padding);

  .project-creators__title {
    @include typography(heading-5);

    margin-bottom: 1rem;
    margin-top: .5rem;
  }

  .project-creators__list {
    column-count: 2;
    padding-left: 0;

    @media (min-width: 540px) {
      column-count: 3;
    }

    @include portrait {
      column-count: 5;
    }
  }

  .project-creators__list-item {
    @include typography(body-small);

    list-style: none;
  }
}
