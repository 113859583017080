
.EditableTextarea {
  display: block;
  position: relative;
  text-align: start;
  width: 100%;

  &:focus,
  &:focus-visible {
    outline-color: $color-pink;
  }

  .EditableTextarea__Text {
    color: $color-black;
    cursor: text;
    transition: color $easing-change;
    word-break: break-word;

    &:focus,
    &:hover {
      color: $color-brown-light;

      .EditableTextarea__Icon {
        opacity: 1;
      }
    }
  }

  .EditableTextarea__Text-placeholder {
    color: $color-brown-light;

    &:focus,
    &:hover {
      color: $color-black;
    }
  }

  .EditableTextarea__Input {
    background-color: transparent;
    border-bottom: 1px solid $color-black;
    resize: none;
    width: 100%;

    &:focus,
    &:focus-visible {
      outline: none;
    }
  }

  .EditableTextarea__Icon {
    margin-left: .5rem;
    opacity: 0;
    transition: opacity $easing-change;
  }
}

.EditableTextarea--contrast {
  .EditableTextarea__Text {
    color: $color-white;

    &:focus,
    &:hover {
      color: rgba($color-white, $alpha: .6);
    }
  }

  .EditableTextarea__Input {
    border-color: rgba($color-white, .6);
    color: $color-white;
  }
}
