
// Layouts

:root {
  --block-margin: 80px;
  --grid-spacing: 8px;
  --grid-padding: 8px;
  --paragraph-margin-top: 20px;

  @include landscape {
    --block-margin: 80px;
    --grid-spacing: 16px;
  }
}
