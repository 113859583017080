
.member-management {
  margin-right: .5rem;

  .member-management__container {
    display: flex;
  }

  .member-management__item {
    background: $color-brown-light;
    border-radius: 50%;
    cursor: pointer;
    display: grid;
    height: 40px;
    margin-right: -8px;
    margin-top: -1px;
    overflow: hidden;
    place-items: center;
    position: relative;
    width: 40px;

    // not the nicest way but its for overlapping the icons just like in the design
    &:nth-child(1) {
      z-index: 6;
    }

    &:nth-child(2) {
      z-index: 5;
    }

    &:nth-child(3) {
      z-index: 4;
    }

    &:nth-child(4) {
      z-index: 3;
    }

    &:nth-child(5) {
      z-index: 2;
    }

    &:nth-child(6) {
      z-index: 1;
    }
  }

  .react-loading-skeleton {
    margin-right: -8px;
    margin-top: -1px;
    overflow: hidden;

    &:nth-child(1) {
      z-index: 6;
    }

    &:nth-child(2) {
      z-index: 5;
    }

    &:nth-child(3) {
      z-index: 4;
    }

    &:nth-child(4) {
      z-index: 3;
    }

    &:nth-child(5) {
      z-index: 2;
    }

    &:nth-child(6) {
      z-index: 1;
    }
  }

  .member-management__item-count {
    @include typography(body-large-sans);

    background-color: $color-pink;
    border-width: 0;
    color: $color-black;
    transition: background-color $easing-change;

    &:focus,
    &:hover {
      background-color: $color-pink-light;
    }
  }

  .member-management__add-button {
    background: $color-white;
    border: 1px solid $color-pink;
    border-radius: 50%;

    @include icon-button-sizing(16px, 16px);
  }

  .member-management__avatar {
    border: 1px solid $color-white;
    position: absolute;
  }
}

.member-management-modal {
  .modal__header {
    margin-bottom: 2rem;
  }

  .member-management-modal__list {
    list-style-image: none;
    margin: 0;
    margin-bottom: 30px;
    margin-right: -1rem;
    max-height: 280px;
    overflow-y: scroll;
    padding: 0;
    padding-right: 1rem;
    scrollbar-color: $color-black transparent;
    scrollbar-width: thin;

    // width
    &::-webkit-scrollbar {
      width: .25rem;
    }

    // track
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    // handle
    &::-webkit-scrollbar-thumb {
      background: $color-black;
    }

    // handle on hover
    &::-webkit-scrollbar-thumb:hover {
      background: $color-brown-light;
    }
  }

  .member-management-modal__list-item {
    @include typography(body-large-sans);

    align-items: center;
    display: grid;
    gap: 20px 10px;
    grid-template-columns: auto 1fr auto;
    margin-bottom: 20px;
  }

  .member-management-modal__avatar {
    border-radius: 50%;
    height: 40px;
    overflow: hidden;
    width: 40px;

    picture {
      height: 40px;
      width: 40px;
    }
  }

  .member-management-modal__list-item-btn {
    border: 1px solid $color-pink;
    border-radius: 50%;
  }

  .member-management-modal__add-member-form {
    margin-bottom: 3rem;
    position: relative;
  }

  .member-management-modal__add-member-input {
    .icon-button {
      position: absolute;
      right: 0;
      top: -.375rem;
    }

    &:focus,
    &:hover {
      border-color: $color-black;
    }
  }
}
