
.text-field {
  margin: 2.5rem 0 0;
  width: 100%;

  // Some extra react textfield styling
  &.text-field--react {
    input::placeholder {
      color: $color-brown-light;
      display: none;
    }
  }

  .text-field__input {
    background: none;
    max-width: calc(100% - 3rem);
    outline: none;
    padding: .25rem 0;
    width: 100%;

    @include typography(input);

    &:hover,
    &:focus {
      outline: none;
    }

    &::placeholder {
      @include typography(input);

      color: $color-black;
    }
  }

  .text-field__label {
    @include typography(input);

    color: $color-brown-light;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: .25rem;
    transition: top $easing-change, font-size $easing-change, color $easing-change;
  }

  .text-field__wrapper {
    border-bottom: 1px solid $color-pink;
    min-height: 1.75rem;
    position: relative;
    transition: border $easing-change;
  }

  &.text-field--open,
  &:focus,
  &:active,
  &:focus-within {
    .text-field__wrapper {
      border-color: $color-black;
    }

    .text-field__label {
      color: $color-brown-mid;
      font-size: .8em;
      top: -.875rem;
    }
  }

  &.text-field--error {
    .text-field__wrapper {
      border-color: $color-error !important;
    }

    .text-field__icon {
      color: $color-error;
      position: absolute;
      right: 0;
      top: 22px;
    }
  }

  .text-field__error {
    color: $color-error;
    display: inline-block;
    margin-top: 4px;
  }

  .text-field__info {
    @include typography(body-small-sans);

    color: $color-brown-mid;
    display: block;
    margin-top: .125rem;
  }
}

.text-field--hidden {
  display: none;
}

.text-field--search {
  .text-field__wrapper {
    min-height: unset;
    position: relative;
  }

  .text-field__icon {
    svg {
      height: 100%;
      width: 100%;
    }

    height: 13px;
    left: 0;
    position: absolute;
    top: 5px;
    width: 13px;
  }

  .text-field__input {
    border-bottom: 1px solid $color-white;
    padding: 4px 0 4px 21px;
  }
}

.text-field--password {
  .text-field__wrapper {
    position: relative;
  }

  .text-field__icon-wrapper {
    position: absolute;
    right: 0;
    top: .375rem;

    button {
      &:focus,
      &:focus-visible {
        outline-color: $color-pink;
      }
    }
  }

  .text-field__icon {
    margin-left: 4px;
    position: unset;
  }

  .text-field__input {
    padding-right: 20px;
  }
}
