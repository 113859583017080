
.dropdown-field {
  margin: 2.5rem 0 0;
  position: relative;

  .dropdown-field__wrapper {
    display: block;
    font-size: 0;
    max-width: 100%;
    position: relative;
  }

  .dropdown-field__input {
    @include typography(input);

    background-color: transparent;
    border: 1px solid $color-pink;
    color: inherit;
    display: inline-block;
    flex: 1 1 100%;
    height: 40px;
    line-height: var(--form-field-line-height);
    margin: 0;
    overflow: hidden;
    padding: 0 15px;
    position: relative;
    transition: color .25s ease-out, background-color .25s ease-out, border-color .25s ease-out;
    vertical-align: bottom;
    white-space: nowrap;
    width: 100%;

    &:hover:not(:disabled),
    &:focus:not(:disabled) {
      background-color: $color-pink-light;
      border-color: $color-pink-light;
    }

    &:focus,
    &:focus-visible {
      outline-color: $color-pink;
    }

    + .dropdown-field__button {
      background-color: transparent;
      border-color: transparent;

      &:hover {
        background-color: transparent;
        color: inherit;
      }
    }

    &:disabled {
      opacity: .4;
      pointer-events: none;
    }
  }

  .dropdown-field__label {
    display: none;
  }

  .dropdown-field__icon {
    // background: linear-gradient(90deg, rgba($color-white, 0) 0%, $color-white 35%);
    display: grid;
    height: calc(100% - 2px);
    place-content: center;
    pointer-events: none;
    position: absolute;
    right: 1px;
    top: 1px;
    width: 40px;
  }

  &.dropdown-field--required {
    .dropdown-field__label {
      &::after {
        content: '*';
        display: inline-block;
        margin-left: .25em;
      }
    }
  }

  &:not(.dropdown-field--required) {
    .dropdown-field__label {
      &::after {
        content: '';
        display: inline-block;
        margin-left: .25em;
      }
    }
  }

  &.dropdown-field--error {
    .dropdown-field__error {
      @include typography(caption);

      color: $color-error;
      display: inline-block;
      max-width: 100%;
      width: auto;
    }

    .dropdown-field__input {
      border-color: $color-error;
    }
  }

  &.dropdown-field--project-selector {
    margin: 0 .75rem 0 0;

    .dropdown-field__input {
      overflow: hidden;
      text-overflow: ellipsis;

      &:disabled {
        border-color: $color-brown-ultralight;
        opacity: 1;
      }

      &:not(:disabled) {
        padding-right: 32px;
      }
    }

    &:not(.dropdown-field--error) {
      .dropdown-field__input {
        width: 268px;
      }
    }
  }
}
