
.icon-field {
  height: 40px;
  width: 40px;

  .icon-field__input {
    height: 0;
    visibility: hidden;
    width: 0;
  }

  .icon-field__label {
    @include typography(input);

    align-items: center;
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    svg:first-child {
      display: block;
    }

    svg:last-child {
      display: none;
    }

    .icon-field__input:checked + &,
    &:hover {
      svg:first-child {
        display: none;
      }

      svg:last-child {
        display: block;
      }
    }
  }

  .icon-field__wrapper {
    height: 100%;
    position: relative;
  }
}

.icon-field--bordered {
  border: 1px solid $color-grey-medium;
  border-radius: 50%;
}
