
.member-management-row {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .member-management-row__user {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .member-management-row__user-name {
    @include typography(body-small);

    margin: 0 10px;
  }
}
