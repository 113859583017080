
.Collapse {
  @include jcb-scrollbar(var(--scrollbar-color));

  &.Collapse--scrollable {
    overflow-y: scroll;
  }

  &.initial-small {
    height: 104px;
    // height: 114px;
  }

  &.initial-medium {
    height: 250px;
  }

  &.initial-large {
    height: 350px;
  }

  &.masked-overflow {
    // scroll bar width, for use in mask calculations
    --scrollbar-width: 8px;

    // Scrollbar color
    --scrollbar-color: #{$color-black};

    //mask fade distance, for use in mask calculations
    --mask-height-bottom: 48px;
    --mask-height-top: 48px;

    // The CSS mask

    // The content mask is a linear gradient from top to bottom
    --mask-image-content: linear-gradient(to bottom, transparent, #000 var(--mask-height-top), #000 calc(100% - var(--mask-height-bottom)), transparent);

    // Here we scale the content gradient to the width of the container minus the scrollbar width. The height is the full container height
    --mask-size-content: calc(100% - var(--scrollbar-width)) 100%;

    // The scrollbar mask is a black pixel
    --mask-image-scrollbar: linear-gradient(#000, #000);

    // The width of our black pixel is the width of the scrollbar. The height is the full container height
    --mask-size-scrollbar: var(--scrollbar-width) 100%;

    // Apply the mask image and mask size variables
    mask-image: var(--mask-image-content), var(--mask-image-scrollbar);

    // Position the content gradient in the top left, and the scroll gradient in the top right
    mask-position: 0 0, 100% 0;

    // We don't repeat our mask images
    mask-repeat: no-repeat, no-repeat;
    mask-size: var(--mask-size-content), var(--mask-size-scrollbar);

    // If content exceeds height of container, overflow!
    overflow-y: auto;

    // Keep some space between content and scrollbar
    padding-right: 20px;
  }

  // width
  &::-webkit-scrollbar {
    width: .25rem;
  }

  // Track
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  // Handle
  &::-webkit-scrollbar-thumb {
    background: var(--scrollbar-color);
  }

  // Handle on hover
  &::-webkit-scrollbar-thumb:hover {
    background: $color-brown-light;
  }

  overflow: hidden;
  transition: height $easing-change-slower;

  + .Collapse__toggle-btn {
    &[aria-expanded='true'] {
      .button__icon {
        transform: rotate(180deg);
      }
    }
  }
}
