
.date-histogram-field {
  height: 260px;
  margin-bottom: .5rem;
  margin-top: 2.5rem;
  position: relative;
}

.date-histogram-field__title {
  margin-bottom: 28px;

  @include typography(heading-6);
}

.date-histogram-field__min-label,
.date-histogram-field__max-label {
  @include typography(heading-7);

  display: block;
  width: 16px;

  span {
    display: block;
  }
}

.date-histogram-field__min-label {
  span {
    transform: translateY(-16px);
  }
}

.date-histogram-field__max-label {
  position: absolute;
  right: 16px;

  span {
    transform: translateY(-16px);
  }
}

.date-histogram-field__dot-container {
  font-size: 0;
  padding-top: 22px;
  position: absolute;
}

.date-histogram-field__dot-column {
  display: inline-block;
  height: 260px;
  margin-left: 5px;
  margin-right: 5px;
  width: 5px;

  &.date-histogram-field__dot-column--black {
    .date-histogram-field__dot {
      background: $color-black;
      opacity: .12;
    }
  }
}

.date-histogram-field__dot {
  background: $color-black;
  border-radius: 50%;
  display: block;
  height: 6px;
  margin-bottom: 5px;
  width: 6px;
}

.date-histogram-field__range-input {
  appearance: none;
  background: linear-gradient($color-grey-mediumdark, $color-grey-mediumdark) no-repeat center;
  background-size: calc(100% - 10px) 1px;
  border: 0;
  border-radius: 1px;
  box-sizing: border-box;
  margin: 0;
  outline: none;
  overflow: hidden;
  padding: 0 2px;
  pointer-events: none;
  width: 100%;

  &:active,
  &:focus {
    outline: none;
  }

  &::-webkit-slider-thumb {
    appearance: none;
    background-color: $color-pink;
    border: 1px solid $color-pink;
    border-radius: 50%;
    cursor: pointer;
    height: 9px;
    pointer-events: all;
    position: relative;
    width: 9px;
    z-index: 100;
  }
}

.date-histogram-field__multi-range {
  display: flex;
  position: relative;
  transform-origin: 0 0;

  .date-histogram-field__range-input {
    position: absolute;
    top: 0;
  }
}
