
.tabs {
  margin-bottom: 2.25rem;
  margin-top: 1.5rem;
  position: relative;

  .tabs__wrapper {
    @include grid-container;
  }

  .tabs__list {
    display: flex;
    grid-column: column 1 / span 12;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .tabs__item {
    @include typography(heading-7);

    border-bottom: 3px solid transparent;
    text-transform: uppercase;
    transition: border $easing-change;

    a {
      display: inline-block;
      padding: 3px 1px;
      text-decoration: none;
    }

    &:not(.tabs__item--active) {
      &:focus,
      &:hover {
        border-bottom: 3px solid $color-pink;
      }
    }

    &.tabs__item--active {
      border-bottom: 3px solid $color-black;

      &:not(:disabled) {
        font-weight: 700;
      }
    }

    &:not(:first-child) {
      margin-left: 32px;
    }
  }
}
