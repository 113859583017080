
.tag {
  @include typography(body-large);

  border: 1px solid $color-pink;
  border-radius: 0;
  color: $color-black;
  // line-height: 0;
  margin: 0 8px;
  padding: 3px 8px;
  transition: background-color $easing-change;

  &:hover,
  &.tag--active {
    background-color: $color-pink-light;
    border: 1px solid $color-pink-light;
  }

  .tag__span {
    @include typography(body-small);

    color: $color-black;
    pointer-events: none;
    text-transform: capitalize;
  }

  &.tag--link {
    display: inline-block;
  }
}

.tag-radio {
  display: block;
  font-size: 0;
  max-width: 100%;
  position: relative;

  label {
    @include typography(button);

    border: 1px solid $color-grey-medium;
    border-radius: 24px;
    cursor: pointer;
    display: inline-block;
    line-height: 1;
    margin: 0;
    padding: 5px 12px;
    user-select: none;
  }

  input {
    float: left;
    height: 0;
    margin-left: -150vw;
    width: 0;

    &:not(:disabled, :checked) {
      + label:hover {
        border: 1px solid $color-teal;
      }
    }

    &:disabled {
      + label {
        opacity: .4;
        pointer-events: none;
      }
    }

    &:checked {
      + label {
        background: $color-white;
      }
    }
  }
}

.tag--light {
  border-color: $color-pink;

  label {
    border-color: $color-white;
  }
}
