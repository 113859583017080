
.menu-overlay {
  background-color: $color-white;
  bottom: 0;
  color: $color-black;
  display: none;
  left: 0;
  padding-top: 15px;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity $easing-change-slower;
  z-index: $z-index-menu-overlay;

  @include landscape {
    box-shadow: 3px 0 20px 0 rgb(200 200 200 / 54%);
    left: 71%;
    transform: translateX(100%);
    transition: transform $easing-change-slower;
  }

  html.has-js & {
    display: block;
    opacity: 0;
    pointer-events: none;

    @include landscape {
      opacity: 1;
    }
  }

  html.has-js.overlay-visible & {
    opacity: 1;
    pointer-events: auto;

    @include landscape {
      transform: translateX(0);
    }
  }

  html:not(.has-js).overlay-visible & {
    display: block;
  }

  .menu-overlay__wrapper {
    height: 100%;
    position: relative;
  }

  .menu-overlay__scroll-container {
    bottom: 0;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 20px 27px;
    position: fixed;
    top: 85px;
    width: 100%;

    @include landscape {
      padding-right: var(--grid-page-padding);
      position: absolute;
    }
  }

  .menu-overlay__links {
    margin-top: 30px;
  }

  .menu-overlay__menu-item {
    margin-bottom: 1.5rem;

    &:last-child {
      margin-bottom: 3rem;
    }
  }

  .menu-overlay__menu-item-link {
    @include typography(heading-2);

    .link__span {
      transition: color $easing-change;

      &:focus,
      &:hover {
        color: $color-pink;
      }
    }

    .link__icon {
      height: unset;
      margin-left: .5rem;
      position: relative;
      top: 1px;
      width: unset;
    }
  }

  .menu-overlay__menu-item-text {
    @include typography(body-small);
  }

  .menu-overlay__link {
    @include typography(heading-3);

    display: block;
    margin-bottom: 1.25rem;
    transition: color $easing-change;
    white-space: nowrap;

    @include landscape {
      margin-bottom: .75rem;
    }

    .link__span {
      &:focus,
      &:hover {
        color: $color-pink;
      }
    }

    .link__icon {
      height: unset;
      position: relative;
      top: 2px;
      width: unset;
    }
  }

  .button--search {
    left: var(--grid-page-padding);
    position: absolute;
    top: 8px;

    @include landscape {
      display: none;
    }
  }

  .button--close {
    position: absolute;
    right: var(--grid-page-padding);
    top: 8px;
  }
}
