
.select-field {
  margin-bottom: 20px;
  position: relative;

  select {
    border: 1px solid $color-grey-medium;
    padding: 15px;
    width: 100%;

    &:focus {
      border-color: $color-teal;
      outline: none;
    }

    &:hover {
      border-color: $color-teal;
    }
  }

  .select-field__icon {
    background: linear-gradient(90deg, rgba($color-white, 0) 0%, $color-white 35%);
    display: grid;
    height: calc(100% - 2px);
    padding: 20px;
    place-content: center;
    pointer-events: none;
    position: absolute;
    right: 1px;
    top: 1px;
  }
}
