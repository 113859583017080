
.ExhibitionSlideshow {
  background-color: $color-black;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 2s $bezier-fancy;

  &.loading-done {
    opacity: 1;
  }

  .ExhibitionSlideshow__OverlaySlide {
    background-color: transparent;
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: background-color 2s $bezier-fancy;
    z-index: 1;

    &.is-active {
      background-color: $color-black;
    }

    // Transition between two overlay pages
    &.ExhibitionSlideshow__OverlaySlide-Switch {
      &.ExhibitionSlideshow__OverlaySlide-Switch-enter {
        .SectionSlide,
        .IntroductionSlide,
        .EndSlide {
          opacity: 0;
        }
      }

      &.ExhibitionSlideshow__OverlaySlide-Switch-enter-active {
        .SectionSlide,
        .IntroductionSlide,
        .EndSlide {
          opacity: 1;
        }
      }

      &.ExhibitionSlideshow__OverlaySlide-Switch-exit {
        .SectionSlide,
        .IntroductionSlide,
        .EndSlide {
          opacity: 1;
        }
      }

      &.ExhibitionSlideshow__OverlaySlide-Switch-exit-active {
        .SectionSlide,
        .IntroductionSlide,
        .EndSlide {
          opacity: 0;
        }
      }

      &.ExhibitionSlideshow__OverlaySlide-Switch-enter-active,
      &.ExhibitionSlideshow__OverlaySlide-Switch-exit-active {
        .SectionSlide,
        .IntroductionSlide,
        .EndSlide {
          transition: opacity 1s $bezier-fancy, transform 1s $bezier-fancy;
        }
      }
    }

    // Transition between overlay page and object page
    &.ExhibitionSlideshow__OverlaySlide-Single {
      &.ExhibitionSlideshow__OverlaySlide-Single-enter {
        .SectionSlide,
        .IntroductionSlide {
          opacity: 0;
        }
      }

      &.ExhibitionSlideshow__OverlaySlide-Single-enter-active {
        .SectionSlide,
        .IntroductionSlide {
          opacity: 1;
        }
      }

      &.ExhibitionSlideshow__OverlaySlide-Single-exit {
        .SectionSlide,
        .IntroductionSlide {
          opacity: 1;
        }
      }

      &.ExhibitionSlideshow__OverlaySlide-Single-exit-active {
        .SectionSlide,
        .IntroductionSlide {
          opacity: 0;
        }
      }

      &.ExhibitionSlideshow__OverlaySlide-Single-enter-active,
      &.ExhibitionSlideshow__OverlaySlide-Single-exit-active {
        .SectionSlide,
        .IntroductionSlide {
          transition: opacity 2s $bezier-fancy, transform 2s $bezier-fancy;
        }
      }
    }
  }
}
