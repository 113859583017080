
.article-form {
  margin: 30px 0 0;
  padding-bottom: 50px;
  position: relative;

  .article-form__grid {
    @include grid-container;
  }

  .article-form__wrapper {
    grid-column: column 1 / span 6;
  }
}
