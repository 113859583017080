
.object-property--simple {
  dd {
    @include typography(body-small);
  }
}

.object-property--tag-list {
  margin: -3px;

  .object-property__filter-button {
    margin: 3px;
  }
}
