
.faq {
  cursor: pointer;
  margin-bottom: .5rem;
  position: relative;

  .faq__question {
    @include typography(heading-4);

    margin-bottom: .25em;

    &.link {
      align-items: flex-start;
      display: flex;

      .link__icon {
        flex: 1.5rem 0 0;
        margin-left: 0;
        margin-right: .25rem;
      }
    }
  }

  .faq__answer {
    margin-bottom: 1rem;
    margin-left: 1.75rem;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height .3s ease, opacity .3s ease .2s;

    .richtext {
      padding: .5rem 0 1.5rem;
    }
  }

  &.faq--open {
    .faq__answer {
      max-height: none;
      opacity: 1;
    }

    .link__icon {
      transform: scaleY(-1);
      transform-origin: center;
    }
  }
}
