
.toggle-button {
  $height: 36px;

  @include typography(button);

  background-color: $color-brown-ultralight;
  border: 1px solid $color-brown-ultralight;
  border-radius: 1000px;
  height: 40px;
  padding-left: calc(#{$height} + 7px);
  padding-right: 15px;
  position: relative;
  transition: all $easing-change;
  width: 102px;

  .toggle-button__icon {
    svg {
      transition: transform $easing-change;
    }

    background-color: transparent;
    border: 1px solid $color-brown-light;
    border-radius: 1000px;
    display: grid;
    height: $height;
    left: 1px;
    place-items: center;
    position: absolute;
    top: 1px;
    transition: all $easing-change;
    width: $height;
  }

  &:not(.toggle-button--active) {
    .toggle-button__icon--on {
      visibility: hidden;
    }

    .toggle-button__text-on {
      display: none;
    }
  }

  .toggle-button__text {
    position: relative;
    top: 1px;
  }

  &.toggle-button--active {
    .toggle-button__icon--off {
      visibility: hidden;
    }

    .toggle-button__icon--on {
      visibility: visible;
    }

    .toggle-button__text-off {
      display: none;
    }

    .toggle-button__text-on {
      color: $color-white;
      display: unset;
    }

    .toggle-button__icon {
      $offset: $height + 1px;

      background-color: $color-white;
      left: calc(100% - #{$offset});
    }

    background-color: $color-green;
    border-color: $color-green;
    padding-left: 14px;
    padding-right: calc(#{$height} + 7px);
  }

  &:disabled {
    opacity: .6;
  }

  &:not(:disabled):hover {
    .toggle-button__icon {
      border-color: $color-brown-light;
      color: $color-brown-mid;
    }

    .toggle-button__text-on {
      color: $color-black;
    }

    background-color: transparent;

    &:not(.toggle-button--active) {
      border-color: $color-brown-mid;
    }
  }
}
