
.explore-header {
  background: transparent;
  height: 120vh;
  overflow: hidden;
  position: relative;
}

.explore-header__container {
  left: 50%;
  position: absolute;
  top: 50vh;
  transform: translate(-50%, -50%);
  width: 100%;
}

.explore-header__text {
  @include typography(heading-1);

  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  letter-spacing: .02em;
  margin: 5rem var(--grid-page-padding) 2rem;
  max-width: 1200px;
  text-align: center;

  @include landscape {
    font-size: 2.125rem;
    margin: 0 auto 2rem;
    width: 66.66%;
  }
}

.explore-header__search {
  @include mobile-only {
    max-width: fit-content;
  }

  .search__input {
    @include typography(body-large-sans);
  }

  align-items: center;
  border-bottom: 1px solid $color-pink;
  display: flex;
}

.explore-header-composition-image {
  opacity: 0;
  position: absolute;
  transition: opacity $easing-change-slower;
  z-index: 100; // shouldn't overlap anything

  .explore-header-composition-image__container {
    position: relative;
    transition: transform $easing-change-extraslow, box-shadow $easing-change-extraslow;

    &::after {
      background-color: $color-black;
      content: '';
      display: block;
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      transition: opacity $easing-change-slower;
      width: 100%;
    }
  }

  img {
    max-width: none; // from normalize but gives incorrectness on borders
    transform-origin: center;
  }

  &.explore-header-composition-image--loaded {
    opacity: 1;
  }

  &:hover {
    .explore-header-composition-image__container {
      box-shadow: 0 0 4px 2px rgb(40 0 0 / 5%);
      transform: translateY(-5px);

      &::after {
        opacity: .2;
      }
    }
  }
}
