.feature-card-grid {
  background: transparent;

  // @include landscape {
  margin: var(--block-margin) 0;
  // }

  .feature-card-grid__grid-container {
    @include grid-container;
  }

  .feature-card-grid__header {
    align-items: flex-end;
    display: flex;
    grid-column: column 1 / span 12;
    justify-content: space-between;
    margin-bottom: calc(-1 * var(--grid-spacing));

    @include landscape {
      grid-column: column 3 / span 10;
    }
  }

  .feature-card-grid__title-wrapper {
    grid-column: column 1 / span 12;
    margin-bottom: 1rem;
    order: 0;

    @include landscape {
      grid-column: column 3 / span 10;
    }
  }

  .feature-card-grid__title {
    @include typography(heading-5);

    margin-bottom: .25rem;
  }

  .feature-card-grid__description {
    @include typography(body-small);

    @include mobile-only {
      margin-bottom: .75rem;
    }
  }

  .feature-card-grid__card {
    grid-column: auto / span 12;
    margin-bottom: 2rem;

    &:nth-last-child(1) {
      margin-bottom: 0;
    }

    @include portrait {
      grid-column: auto / span 6;

      &:nth-child(n+2):nth-last-child(-n+2) {
        margin-bottom: 0;
      }
    }

    @include landscape {
      grid-column: auto / span 4;

      &:nth-child(n+3):nth-last-child(-n+3) {
        margin-bottom: 0;
      }
    }
  }

  .feature-card--default .feature-card__title {
    @include typography(heading-4);

    margin-top: var(--grid-padding);
  }

  .feature-card-grid__pagination {
    margin-top: 2rem;
    text-align: center;

    @include landscape {
      grid-column: column 1 / span 12;
    }
  }

  .feature-card-grid__buttons {
    margin-bottom: 1rem;
  }
}

.feature-card-grid--header {
  .feature-card-grid__header {
    grid-column: column 1 / span 12;
  }

  .feature-card-grid__title {
    @include typography(heading-2);

    margin: 0;
  }
}

.feature-card-grid--workspace {
  margin: calc(var(--margin) * 2) 0;

  @include landscape {
    margin: 1.25rem 0;
  }

  .feature-card-grid__header {
    @include landscape {
      grid-column: column 1 / span 10;
    }
  }
}

.feature-card-grid--project-workspace {
  margin: 36px 0 48px;
}

.feature-card-grid--paginated {
  margin: var(--margin) 0;
}

.feature-card-grid--compact {
  .feature-card-grid__card {
    @include portrait {
      grid-column: auto / span 4;
    }

    @include landscape {
      grid-column: auto / span 3;
    }
  }
}
