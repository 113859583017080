
// Layouts

:root {
  --layout-content: column 1 / span 12;
  --layout-full: column 1 / span 12;
  --layout-center: column 1 / span 12;

  @include landscape {
    --layout-content: column 1 / span 6;
    --layout-full: column 1 / span 12;
    --layout-left: column 1 / span 6;
    --layout-right: column 7 / span 12;
  }
}
