
.form {
  .form__title {
    @include typography(heading-3);

    margin-bottom: 1.25rem;
  }

  .form__submit-btn {
    @include typography(button);

    display: flex;
    margin-left: auto;
    margin-top: 2.5rem;
  }

  p {
    &:not(:first-child) {
      margin-top: .5rem;
    }
  }
}
