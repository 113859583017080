
:root {
  --heading-4-font-size: 18px;
  --heading-4-font-style: normal;
  --heading-4-font-variant: normal;
  --heading-4-font-stack: #{$lyon-display-font-stack};
  --heading-4-font-weight: 500;
  --heading-4-letter-spacing: .01em;
  --heading-4-line-height: 1.333em;
  --heading-4-margin-bottom: .5em;
  --heading-4-margin-top: 1em;
  --heading-4-word-spacing: normal;
}
