
.member-management-form {
  ul {
    list-style: none;
    padding: 0;
  }

  li {
    margin: 20px 0;
  }
}
