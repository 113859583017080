@mixin jcb-scrollbar($handle-color: $color-black, $hover-color: $color-brown-light) {
  // width
  &::-webkit-scrollbar {
    width: .25rem;
  }

  // Track
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  // Handle
  &::-webkit-scrollbar-thumb {
    background: #{$handle-color};
  }

  // Handle on hover
  &::-webkit-scrollbar-thumb:hover {
    background: #{$hover-color};
  }

  // Firefox
  scrollbar-color: #{$handle-color} transparent;
  scrollbar-width: thin;
}
