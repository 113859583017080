
.object-frame {
  padding-bottom: 75%;
  position: relative;

  .object-frame__image {
    display: contents;
    height: 100%;
    width: 100%;

    .picture__image {
      bottom: 0;
      max-height: 100%;
      opacity: 1;
      position: absolute;
      width: auto;
    }
  }
}

.object-frame--centered {
  // Show image in top center of the frame
  .object-frame__image {
    .picture__image {
      bottom: auto;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.object-frame--top-center {
  // Show image in top center of the frame
  .object-frame__image {
    .picture__image {
      bottom: auto;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
    }
  }
}

.object-frame--top-left {
  // Show image in top left of the frame
  .object-frame__image {
    .picture__image {
      bottom: unset;
      left: 0;
      top: 0;
    }
  }
}

.object-frame--ratio-portrait {
  padding-bottom: 150%;
}
