h1,
.h1 {
  @include typography(heading-1);
}

h2,
.h2 {
  @include typography(heading-2);
}

h3,
.h3 {
  @include typography(heading-3);
}

h4,
.h4 {
  @include typography(heading-4);
}

h5,
.h5 {
  @include typography(heading-5);
}

h6,
.h6 {
  @include typography(heading-6);
}

.h7 {
  @include typography(heading-7);

  display: block;
}
