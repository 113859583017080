
.item-list {
  padding: 50px 0;

  .item-list__grid-container {
    @include grid-container;
  }

  .item-list__header {
    align-items: center;
    display: flex;
    grid-column: span 12;
    height: 40px; // Height of one button
    justify-content: space-between;
    margin-bottom: 5px;
  }

  .item-list__title {
    @include quick-layout((1, 2), (1, 4), (1, 6), (1, 6));
    @include typography(heading-4);
  }

  .item-list__items {
    @include quick-layout((1, 2), (1, 6), (1, 12), (1, 12));
  }
}
