
.section-annotations-overview {
  margin-bottom: var(--block-margin);
  position: relative;

  &:first-child {
    padding-top: 3rem;
  }

  .section-annotations-overview__grid-container {
    @include grid-container;
  }

  .section-annotations-overview__header {
    align-items: center;
    display: flex;
    grid-column: span 12;
    height: 40px; // Height of one button
    justify-content: space-between;
    margin-bottom: 5px;
  }

  .section-annotations-overview__title {
    @include typography(heading-2);
  }
}
