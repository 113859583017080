:root {
  --body-small-sans-font-size: 11px;
  --body-small-sans-font-stack: #{$open-sans-font-stack};
  --body-small-sans-font-style: normal;
  --body-small-sans-font-variant: normal;
  --body-small-sans-font-weight: 400;
  --body-small-sans-letter-spacing: .01em;
  --body-small-sans-line-height: 1.455em;
  --body-small-sans-margin-bottom: .5em;
  --body-small-sans-margin-top: 1em;
}
