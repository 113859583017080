
.user-info {
  margin-left: 1.5rem;
  position: relative;

  .user-info__avatar {
    margin-left: 10px;
    position: relative;
  }

  .avatar__picture::after {
    background-color: transparent;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: background-color $easing-change;
    width: 100%;
  }

  .user-info__button {
    align-items: center;
    display: flex;
    gap: .75rem;
  }

  .user-info__menu {
    background-color: $color-white;
    box-shadow: 0 0 5px 0 rgb(0 0 0 / 10%);
    list-style-type: none;
    min-width: 180px;
    padding: 16px 8px;
    position: absolute;
    right: -130%;
    top: 3.125rem;
    z-index: 1024;
  }

  &.user-info--open {
    .user-info__button-icon {
      transform: translate(0, -4px) rotate(180deg);
    }
  }

  .floating-menu-item {
    background-color: transparent;
    cursor: pointer;
    list-style-type: none;
    padding: 4px 8px;
    text-align: start;
    transition: background-color $easing-change;

    &:focus,
    &:hover {
      background-color: $color-pink-light;
    }

    .floating-menu-item__anchor {
      @include base-link($color-black, none);
      @include typography(body-large-sans);

      display: inline-block;
      width: 100%;
    }
  }

  &:focus,
  &:hover {
    .avatar__picture::after {
      background-color: rgba($color-white, .7);
    }
  }
}
