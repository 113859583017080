
// NOTE: FIXME: This exists outside of .ProjectPublishing, so is incorrectly scoped!
.ProjectPublishing__Wrapper {
  background-color: $color-white;
  box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 10%);
  padding: .75rem;
  position: absolute;
  right: 0;
  top: 3.125rem;
  width: 420px;
  z-index: 4;

  // React Transition Group settings
  &.ProjectPublishing__Wrapper-enter {
    opacity: 0;
    transform: translateY(-10px);
  }

  &.ProjectPublishing__Wrapper-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }

  &.ProjectPublishing__Wrapper-exit {
    opacity: 1;
  }

  &.ProjectPublishing__Wrapper-exit-active {
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 300ms, transform 300ms;
  }

  .ProjectPublishing__Tabs {
    margin-bottom: 1rem;
  }

  .ProjectPublishing__Notification {
    margin-bottom: .75rem;
  }

  .ProjectPublishing__Notification-Transition {
    &.ProjectPublishing__Notification-Transition-enter {
      opacity: 0;
    }

    &.ProjectPublishing__Notification-Transition-enter-active {
      opacity: 1;
    }

    &.ProjectPublishing__Notification-Transition-exit {
      opacity: 1;
    }

    &.ProjectPublishing__Notification-Transition-exit-active {
      opacity: 0;
    }

    &.ProjectPublishing__Notification-Transition-enter-active,
    &.ProjectPublishing__Notification-Transition-exit-active {
      transition: opacity .15s ease-in-out, transform .15s ease-in-out;
    }
  }

  .ProjectPublishing__Tab-Transition {
    &.ProjectPublishing__Tab-Transition-enter {
      opacity: 0;
    }

    &.ProjectPublishing__Tab-Transition-enter-active {
      opacity: 1;
    }

    &.ProjectPublishing__Tab-Transition-exit {
      opacity: 1;
    }

    &.ProjectPublishing__Tab-Transition-exit-active {
      opacity: 0;
    }

    &.ProjectPublishing__Tab-Transition-enter-active,
    &.ProjectPublishing__Tab-Transition-exit-active {
      transition: opacity .15s ease-in-out, transform .15s ease-in-out;
    }
  }

  .ProjectPublishing__Content {
    background-color: $color-brown-ultralight;
    display: flex;
    flex-direction: column;
    // gap: 1rem;
    padding: 1rem;
    width: 100%;

    > :not(.modal) {
      margin-top: 1rem;
    }

    :first-child {
      margin-top: 0;
    }
  }

  .ProjectPublishing__Content-paragraph {
    @include typography(body-large-sans);

    display: block;
    margin-top: .25rem;

    &:not(:last-child) {
      margin-bottom: .25rem;
    }
  }

  .ProjectPublishing__LinkField {
    .text-field {
      max-width: 285px;
    }
  }

  .ProjectPublishing__Exhibition-status {
    border-radius: 50%;
    display: inline-block;
    height: .75rem;
    margin-right: .5rem;
    position: relative;
    top: 1px;
    width: .75rem;

    &.pending {
      background-color: $color-yellow;
    }

    &.approved {
      background-color: $color-green;
    }
  }

  .ProjectPublishing__Exhibition-Open-Btn,
  .ProjectPublishing__PublicLink-Open-Btn,
  .ProjectPublishing__Export-Download-Btn {
    margin-top: .5rem;
    padding: 1px 0;

    .button__span {
      position: relative;
      text-align: start;
      top: 2px;
    }

    &:focus,
    &:hover {
      .button__icon {
        color: $color-pink;
      }
    }
  }

  .ProjectPublishing__Exhibition-Submit-Btn {
    align-self: flex-end;
    margin-top: .5rem;
    width: fit-content;
  }
}
