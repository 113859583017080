
.snackbar {
  --translate-x: 0;
  --translate-y: 0;

  box-shadow: 0 0 5px 0 rgb(0 0 0 / 10%);
  max-width: 500px;
  position: fixed;

  .snackbar__close-btn {
    position: absolute;
    right: 0;
    top: 0;
  }

  &.snackbar--bottom-right {
    --translate-x: 50%;
    --translate-y: 0;

    bottom: 16px;
    right: 16px;
    // transform: translate(600px, 0);
    // transition: transform $easing-change-slow;
  }

  // React Transition Group styling
  &.snackbar-enter {
    opacity: 0;
    transform: translate(var(--translate-x), var(--translate-y));
  }

  &.snackbar-enter-active {
    opacity: 1;
    transform: translate(0, 0);
  }

  &.snackbar-exit {
    opacity: 1;
    transform: translate(0, 0);
  }

  &.snackbar-exit-active {
    opacity: 0;
    transform: translate(var(--translate-x), var(--translate-y));
  }

  &.snackbar-enter-active,
  &.snackbar-exit-active {
    transition: opacity .35s $bezier-fancy, transform .35s $bezier-fancy;
  }
}
