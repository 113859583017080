
.article-video-embed {
  padding-bottom: 40px;

  .article-video-embed__grid-container {
    @include grid-container;
  }

  .article-video-embed__video-embed {
    grid-column: span 11;
    margin-left: calc((var(--grid-gutter) - var(--grid-margin)));
  }

  .video-embed__caption {
    margin-left: calc((var(--grid-gutter) + var(--grid-margin)));
  }
}
