
.article-error {
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  padding-left: var(--grid-page-padding);
  padding-right: var(--grid-page-padding);
  position: absolute;
  right: 0;
  top: 0;

  .article-error__title {
    @include typography(heading-2);

    margin-top: 1.875rem;
    text-align: center;
  }

  .article-error__subtitle {
    @include typography(body-large);

    text-align: center;

    @include landscape {
      max-width: 80%;
    }

    @include desktop {
      max-width: 60%;
    }
  }

  .article-error__buttons {
    margin-top: 1.5rem;

    &:not(:last-child) {
      display: inline-block;
      margin-right: 1rem;
    }
  }

  // Forbidden icon scaling
  .pencil-guard {
    width: 85px;
  }
}
