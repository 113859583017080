
:root {
  --heading-3-font-size: 20px;
  --heading-3-font-stack: #{$lyon-display-font-stack};
  --heading-3-font-style: normal;
  --heading-3-font-variant: normal;
  --heading-3-font-weight: 500;
  --heading-3-letter-spacing: .02em;
  --heading-3-line-height: 1.4em;
  --heading-3-margin-bottom: .5em;
  --heading-3-margin-top: 1em;
  --heading-3-word-spacing: normal;
}
