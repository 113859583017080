.modal-stack {
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  transition: transform $easing-change-slow;
  width: 100vw;
  z-index: $z-index-modal-stack;

  .modal-stack__modals {
    position: relative;
  }

  &:not(.modal-stack--active) {
    pointer-events: none;
  }
}

.modal-stack-modal {
  background-color: $color-grey-superlight;
  max-width: 100vw;
  min-height: 100vh;
  min-width: 100vw;
  position: absolute;
  top: 0;
  width: 100vw;

  .modal-stack-modal__content {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    z-index: 0;
  }

  .modal-stack-modal__back-button-bar {
    padding: 20px;
    position: absolute;
    z-index: 1;

    @include grid-container;
  }

  .modal-stack-modal__back-button-bar-inner {
    grid-column: column 1 / span 12;
    margin-left: -16px;
  }

  .modal-stack-modal__close-button {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: $z-index-modal-stack + 2;
  }

  .modal-stack-modal__error {
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
    width: 100vw;
  }

  .modal-stack-modal__loading {
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
    width: 100vw;
  }
}
