
.object-cards {
  background: $color-grey-extralight;

  .object-cards__container {
    @include grid-container;

    padding-bottom: 50px;
    padding-top: 50px;
  }

  .object-cards__title {
    @include typography(heading-4);

    grid-column: span 12;
    margin-bottom: 15px;
    margin-top: 15px;
    text-transform: uppercase;
  }

  .object-cards__card {
    grid-column: span 4;
  }
}
