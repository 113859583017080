
:root {
  --heading-2-font-size: 24px;
  --heading-2-font-stack: #{$lyon-display-font-stack};
  --heading-2-font-style: normal;
  --heading-2-font-variant: normal;
  --heading-2-font-weight: 500;
  --heading-2-letter-spacing: .02em;
  --heading-2-line-height: 1.333em;
  --heading-2-margin-bottom: .33em;
  --heading-2-margin-top: 1em;
  --heading-2-word-spacing: normal;
}
