
.object-carousel {
  $default-height: 500px;

  padding-bottom: 40px;
  position: relative;

  .object-carousel__grid-container {
    @include landscape {
      @include grid-container;

      &::before {
        background: $color-grey-americana;
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 36%;
        z-index: 5;
      }
    }
  }

  .picture {
    img {
      height: 100%;
      max-height: $default-height;
      width: auto;
    }
  }

  .object-carousel__text-container {
    background: $color-grey-americana;
    display: flex;
    flex-direction: column;
    grid-column: span 4;
    position: relative;
    z-index: 10;

    .richtext {
      display: none;
      padding: 0 var(--grid-page-padding) 24px;
      transition: opacity .5s ease;

      @include landscape {
        padding: 0;
      }

      &[data-enabled] {
        display: block;
        opacity: 0;
      }

      &[data-active] {
        opacity: 1;
      }

      p {
        font-size: .875rem;
      }
    }
  }

  .object-carousel__swiper-container-container {
    grid-column: span 6;
    height: $default-height;
    overflow: visible;
  }

  .swiper-container {
    overflow: visible;
  }

  .swiper-slide {
    width: auto;
  }

  .swiper-navigation {
    grid-column: column 5 / span 7;
    margin: 10px 0;
    position: relative;
    z-index: 10;

    @include mobile-only {
      padding: 0 var(--grid-page-padding);
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    @include icon-button-sizing(16px, 16px);
  }

  .swiper-button-prev {
    transform: rotate(180deg);
    transform-origin: center;
  }

  .swiper-button-disabled {
    opacity: .5;
    pointer-events: none;
  }
}
