
.navigation-tab {
  background: none;
  border: 0;
  margin-right: 10px;
  outline: none;
  padding: 15px 20px;

  &.navigation-tab--active {
    background: $color-white;
  }
}
