
.SectionSlide {
  @include grid-container;

  background-color: $color-black;
  height: 100%;
  padding-bottom: 2rem;
  padding-top: 8rem;
  position: relative;
  z-index: 1;

  @include portrait {
    padding-bottom: 15%;
    padding-top: 15%;
  }

  .SectionSlide__Wrapper {
    color: $color-white;
    grid-column: column 1 / span 7;
    z-index: 1;

    @include desktop {
      grid-column: column 2/ span 6;
    }
  }

  .SectionSlide__Title {
    margin-bottom: 1.25rem;
  }

  .SectionSlide__Introduction {
    @include typography(body-large);
  }

  .SectionSlide__Collapse-Btn {
    color: $color-white;
  }

  .Collapse.masked-overflow {
    --scrollbar-color: #fff;
  }
}
