
.floating-footer {
  bottom: 10px;
  position: fixed;
  width: 100%;
  z-index: 100;

  .floating-footer__grid-container {
    @include grid-container;
  }

  .floating-footer__buttons {
    @include quick-layout((1, 2), (1, 12), (1, 12), (1, 12));

    align-items: center;
    display: flex;
    justify-content: flex-end;
  }
}
