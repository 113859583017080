
.article-header {
  padding-top: var(--header-padding);
  position: relative;

  .article-header__grid {
    @include grid-container;
  }

  .article-header__title {
    @include typography(heading-1);

    grid-column: column 1 / span 12;

    @include landscape {
      grid-column: column 1 / span 6;
    }
  }
}
