:root {
  --heading-5-font-size: 16px;
  --heading-5-font-stack: #{$open-sans-font-stack};
  --heading-5-font-style: normal;
  --heading-5-font-variant: normal;
  --heading-5-font-weight: 700;
  --heading-5-letter-spacing: .1em;
  --heading-5-line-height: 1.375em;
  --heading-5-margin-bottom: .5em;
  --heading-5-margin-top: 1em;
  --heading-5-word-spacing: normal;
  --heading-5-text-transform: uppercase;
}
