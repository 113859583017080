
.ActionTextfield {
  .ActionTextfield__Wrapper {
    background-color: $color-white;
    border: 1px solid $color-pink;
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
  }

  .text-field {
    margin: 0;
    max-width: 280px;
  }

  .text-field__wrapper {
    border-width: 0;
  }

  .text-field__input {
    padding: 12px;
    padding-right: 16px;
    text-overflow: ellipsis;
  }

  .button {
    width: fit-content;
  }
}
