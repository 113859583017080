@use 'sass:list';
@use 'sass:string';

// Grid variables
//
// You can add or remove breakpoints at will,
// but be sure to add/remove/modify the relevant mixins as well!

:root {
  // Breakpoints
  --breakpoint-mobile: #{$mobile};
  --breakpoint-portrait: #{$portrait};
  --breakpoint-landscape: #{$landscape};
  --breakpoint-notebook: #{$notebook};
  --breakpoint-desktop: #{$desktop};

  // Breakpoint reference (for javascript)
  --breakpoint: var(--breakpoint-mobile);

  @include portrait {
    --breakpoint: var(--breakpoint-portrait);
  }

  @include landscape {
    --breakpoint: var(--breakpoint-landcape);
  }

  @include notebook {
    --breakpoint: var(--breakpoint-notebook);
  }

  @include desktop {
    --breakpoint: var(--breakpoint-desktop);
  }

  // Document constraints
  --document-min-width: #{$min-width};
  --document-max-width: #{$max-width};

  // Grid settings
  --grid-columns: 2;
  --grid-gutter: 8px;
  --grid-gutter-narrow: 8px;
  --grid-spacing: 8px;
  --grid-margin: 8px;
  --grid-max-width: 100%;
  --grid-debug-color: #f06;

  @include portrait {
    --grid-columns: 12;
    --grid-gutter: 16px;
    --grid-debug-color: #0cf;
  }

  @include landscape {
    --grid-gutter: 48px;
    --grid-gutter-narrow: 16px;
    --grid-debug-color: #0f9;
  }

  @include notebook {
    --grid-spacing: 16px;
    --grid-gutter: 64px;
    // --grid-gutter-narrow: 32px;
    --grid-debug-color: #c3f;
  }

  // Component settings
  --header-padding: 8rem;
  --margin: 8px;
  // --block-margin-top: 8px;
  --block-margin-bottom: calc(var(--margin) * 1.5);
  --beat: 8px;
  --footer-height: 164px;

  @include portrait {
    --margin: 16px;
    --block-margin-top: 16px;
  }

  @include landscape {
    --header-padding: 8rem;
    --margin: 32px;
  }

  @include notebook {
    --margin: 48px;
  }

  // overlay settings
  --repeating-width: calc(100% / var(--grid-columns));
  --column-width: calc((100% / var(--grid-columns)) - var(--grid-gutter));
  --background-width: calc(100% + var(--grid-gutter));
  --background-columns: repeating-linear-gradient(to right, var(--grid-debug-color), var(--grid-debug-color) var(--column-width), transparent var(--column-width), transparent var(--repeating-width));

  // Calculated grid column width
  --grid-page-padding: calc(var(--grid-gutter) + var(--grid-margin));
  --grid-column-absolute: calc((100vw - (calc(var(--grid-page-padding) * 2)) - var(--grid-gutter)) / 12);
}

// Returns a padding offset for elements that need to be aligned with the grid that are themselves not in the grid
@function get-grid-column-padding-offset($gap-count: 0, $column-start: 1) {
  $column-start: $column-start - 1;
  $total-padding-width: calc(var(--grid-page-padding) * 2);
  $total-gutter-width: calc(var(--grid-gutter) * #{$gap-count});

  $result: calc((100vw - #{$total-padding-width} - #{$total-gutter-width}) / 12 * #{$column-start});

  @return $result;
}

@mixin custom-variable($varname, $mobile, $portrait, $landscape, $notebook) {
  :root {
    #{$varname}: $mobile;

    @include portrait {
      #{$varname}: $portrait;
    }

    @include landscape {
      #{$varname}: $landscape;
    }

    @include notebook {
      #{$varname}: $notebook;
    }
  }
}

@mixin grid-container($columns: 'repeat(var(--grid-columns), [column] minmax(0, 1fr))') {
  display: grid;
  grid-gap: var(--grid-spacing) var(--grid-gutter);
  grid-template-columns: string.unquote($columns);
  margin-left: auto;
  margin-right: auto;
  max-width: calc(var(--grid-max-width) + (var(--grid-gutter) * 2) + (var(--grid-margin) * 2));
  padding-left: var(--grid-page-padding);
  padding-right: var(--grid-page-padding);
}

@mixin subgrid-container($columns: auto, $inline: false, $narrow: false) {
  @if $inline {
    display: inline-grid;
  } @else {
    display: grid;
  }

  @if $narrow {
    grid-gap: var(--grid-spacing) var(--grid-gutter-narrow);
  } @else {
    grid-gap: var(--grid-spacing) var(--grid-gutter);
  }

  grid-template-columns: string.unquote($columns);
}

@mixin quick-layout($mobile, $portrait, $landscape, $notebook) {
  @if $mobile {
    $column: column #{list.nth($portrait, 1)};

    @if list.nth($mobile, 1) == 'auto' {
      $column: 'auto';
    }

    grid-column: #{$column} / span #{list.nth($portrait, 2)};
  }

  @if $portrait {
    @include portrait {
      $column: column #{list.nth($portrait, 1)};

      @if list.nth($portrait, 1) == 'auto' {
        $column: 'auto';
      }

      grid-column: #{$column} / span #{list.nth($portrait, 2)};
    }
  }

  @if $landscape {
    @include landscape {
      $column: column #{list.nth($landscape, 1)};

      @if list.nth($landscape, 1) == 'auto' {
        $column: 'auto';
      }

      grid-column: #{$column} / span #{list.nth($landscape, 2)};
    }
  }

  @if $notebook {
    @include notebook {
      $column: column #{list.nth($notebook, 1)};

      @if list.nth($notebook, 1) == 'auto' {
        $column: 'auto';
      }

      grid-column: #{$column} / span #{list.nth($notebook, 2)};
    }
  }
}
