
// Base colors
// $color-black: #000;
$color-black: #280000;
$color-white: #fff;

// Theme colors
$color-teal: #47a7aa;
$color-pink-light: #ffcccb;
$color-pink: #e88f8f;
$color-pink-dark: #bd5b5d;
$color-brown-mid: #78564a;
$color-brown-light: #a38c83;
$color-brown-ultralight: #e7e0dc;

// Greyscale colors
$color-grey-americana: #faf9f8;
$color-grey-extradark: #282828;
$color-grey-dark: #484848;
$color-grey-mediumdark: #666;

$color-grey-medium: #c8c8c8;
$color-grey-light: #dedede;
$color-grey-extralight: #f0f0f0;
$color-grey-superlight: #faf9f8;

// Functional - Tertiary colors
$color-error: #de0101;
$color-info: #79bde8;
$color-red: #de0101;
$color-yellow: #d4af37;
$color-green: #37a526;

// Transparencies
$transparency-overlay: rgba($color-white, .8);

// Beziers
$bezier-fancy: cubic-bezier(.26, .88, .57, .9);

// Easings
$easing-change: .15s $bezier-fancy;
$easing-change-slower: .35s $bezier-fancy;
$easing-change-faster: .05s $bezier-fancy;
$easing-change-slow: .5s $bezier-fancy;
$easing-change-extraslow: 1s $bezier-fancy;
$easing-linear: .15s linear;

// Layers / z-indexes
$z-index-hide: -1;
$z-index-hit-target: 100;
$z-index-form-field-button: 10;
$z-index-object-overlay: 600;
$z-index-mirador-menu: 500;
$z-index-modal-stack: 1000;
$z-index-menu-overlay: 2048;

// Text shadows
//$text-shadow-on-photo: 1px 1px 15px rgba(0, 0, 0, .15);

// Exposed variables
:root {
  // None yet
  //--bezier-fancy: $bezier-fancy;
}
