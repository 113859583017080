
.icon-button {
  @include typography(button);

  border: 1px solid transparent;
  color: $color-black;
  contain: content;
  cursor: pointer;
  display: inline-block;
  height: 40px;
  overflow: hidden;
  padding: 11px;
  position: relative;
  transition: color $easing-change, background-color $easing-change, border-color $easing-change;
  user-select: none;
  vertical-align: bottom;
  white-space: nowrap;
  width: 40px;

  &:not([disabled]):hover {
    background-color: $color-pink-light;
    border-color: $color-pink-light;
  }

  &:focus,
  &:focus-visible {
    outline-color: $color-pink;
  }

  &[disabled] {
    cursor: initial;
    opacity: .25;
  }

  .icon-button__icon {
    display: block;
    height: 100%;
    pointer-events: none;
    width: 100%;

    > svg {
      display: block;
      fill: currentcolor;
      height: 100%;
      width: 100%;
    }
  }
}

.icon-button--background {
  background-color: $color-grey-light;
  border-color: transparent;
  color: $color-grey-extradark;

  &:hover:not(.button--disabled, :disabled),
  &:focus:not(.button--disabled, :disabled),
  &.button--selected {
    background-color: $color-grey-extradark;
    border-color: transparent;
    color: $color-grey-extralight;
  }
}

.icon-button--background-on-white {
  background-color: $color-white;
  border-color: transparent;
  color: $color-black;

  &:hover:not(.button--disabled, :disabled),
  &:focus:not(.button--disabled, :disabled),
  &.button--selected {
    background-color: $color-pink-light;
    border-color: $color-pink-light;
  }
}

.icon-button--default {
  //
}

.icon-button--active {
  background-color: $color-white;
  border-color: transparent;
  color: $color-black;
}

.icon-button--big {
  height: 60px;
  padding: 15px;
  width: 60px;
}

.icon-button--small {
  height: 26px;
  padding: 4px;
  width: 26px;

  svg {
    transform: scale(.75); // Default button size is 16x16, scaling down gives best results for some cases.. Not ideal
  }
}

.icon-button--secondary {
  background-color: $color-white;
  border: 1px solid $color-pink;

  &:focus,
  &:hover {
    background-color: $color-pink-light;
    border-color: $color-pink-light;
  }
}

.icon-button--clean {
  background-color: transparent;
  border: unset;
  color: currentcolor;

  &:not(:disabled) {
    &:hover,
    &:focus {
      background-color: transparent;
      border: unset;
      color: $color-pink;
    }
  }
}

.icon-button--outline {
  border: 1px solid $color-pink;
}

.icon-button--exhibition {
  background-color: $color-yellow;
  border: 1px solid $color-yellow;
  color: $color-black;

  &:disabled {
    color: rgba($color-black, .3);
  }

  &:hover:not(.button--disabled, :disabled),
  &:focus:not(.button--disabled, :disabled),
  &.button--selected {
    background-color: $color-yellow;
    border-color: $color-yellow;
    color: $color-white;
  }
}

.icon-button--primary {
  background-color: $color-pink;
  border: 1px solid $color-pink;
  color: $color-black;

  &:hover:not(.button--disabled, :disabled),
  &:focus:not(.button--disabled, :disabled),
  &.button--selected {
    background-color: $color-pink-light;
    border-color: $color-pink-light;
    color: $color-black;
  }
}
