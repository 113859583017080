
.object-info {
  @include grid-container;

  margin-bottom: 120px;

  dl {
    display: grid;
    row-gap: 24px;

    @include portrait {
      row-gap: 20px;
    }
  }

  dt {
    @include typography(heading-7);

    margin-bottom: 4px;
  }

  dd {
    margin: 0;
  }

  .object-info__back-btn {
    grid-column: span 2;
    margin-top: 12px;
    transform: rotate(180deg);

    @include portrait {
      grid-column: span 12;
    }
  }

  .object-info__column-left {
    grid-column: column 1 / span 2;
    margin-bottom: .75rem;

    @include portrait {
      grid-column: column 1 / span 6;
      margin-bottom: 0;
    }

    dl {
      margin-top: 24px;
    }

    dd {
      @include typography(body-large);

      margin: 0;
    }
  }

  .object-info__column-center {
    grid-column: column 1 / span 2;
    margin-bottom: 2.5rem;

    @include portrait {
      grid-column: column 7 / span 3;
      margin-bottom: 0;
      margin-top: 5px;
    }
  }

  .object-info__column-right {
    grid-column: column 1 / span 2;
    margin-bottom: 2.5rem;

    @include portrait {
      grid-column: column 10 / span 3;
      margin-bottom: 0;
    }
  }

  .object-info__title {
    @include typography(heading-2);
  }

  .object-info__collection {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .object-info__grid-4-columns {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }

  .object-info__thumbnail {
    align-items: flex-end;
    display: flex;
    gap: .5rem;
    justify-content: space-between;
    margin-bottom: 1.5rem;

    @include portrait {
      margin-bottom: 1.25rem;
    }
  }

  .object-info__image {
    flex: 1;
    padding-bottom: 0;

    .picture__image {
      max-height: 200px !important;
      position: unset !important;
    }
  }

  .object-info__scale-pencil {
    position: relative;
    z-index: 1;

    :nth-child(2) {
      margin-left: 4px;
    }
  }

  .object-info__scale-pencil-width {
    @include typography(heading-7);

    bottom: 2px;
    left: 46px;
    position: absolute;
  }

  .object-info__scale-pencil-height {
    @include typography(heading-7);

    bottom: 34px;
    left: 16px;
    position: absolute;
  }
}
