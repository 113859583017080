
.object-annotation-overview {
  @include grid-container;

  overflow: hidden;
  padding-bottom: 20px;
  padding-top: 20px;
  transition: max-height .5s ease, background-color .3s ease;

  @include mobile-only {
    row-gap: 2rem;
  }
}

.object-annotation-overview--active {
  .object-annotation-overview__toggle-open-btn {
    .button__icon {
      transform: rotate(180deg);
    }
  }
}

.object-annotation-overview__picture-container {
  background-color: $color-brown-ultralight;

  @include mobile-only {
    grid-column: column 1 / span 1;
  }

  @include portrait {
    grid-column: column 1 / span 3;
  }
}

.object-annotation-overview__information {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include mobile-only {
    grid-column: column 2 / span 1;
  }

  @include portrait {
    grid-column: column 4 / span 7;
  }
}

.object-annotation-overview__title {
  @include typography(heading-4);

  margin-bottom: .25rem;
  margin-top: 0;
}

.object-annotation-overview__author,
.object-annotation-overview__year {
  @include typography(body-small);
}

.object-annotation-overview__author {
  margin-bottom: .25rem;
}

.object-annotation-overview__public-total,
.object-annotation-overview__private-total {
  @include typography(body-small);

  display: inline-block;
  margin-bottom: 10px;
  margin-right: 15px;
}

.object-annotation-overview__toggle-open-btn {
  @include typography(link);

  display: block;

  @include portrait {
    margin-bottom: 1.5rem;
  }
}

.object-annotation-overview__annotations-container {
  counter-reset: listCounter;
  grid-column: column 1 / span 2;
  list-style-type: none;
  padding: 0;

  @include portrait {
    grid-column: column 4 / span 9;
  }
}

.object-annotation-overview__annotation {
  @include typography(body-large);

  column-gap: 20px;
  display: grid;
  flex-grow: 1;
  grid-template-columns: 0 .35fr 1fr 60px;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  opacity: 0;

  &::before {
    content: counter(listCounter, decimal-leading-zero);
    counter-increment: listCounter;
    margin-right: 2em;

    @include mobile-only {
      flex: 0 1 15%;
      margin-right: 0;
      order: 0;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  .object-annotation-overview--active & {
    opacity: 1;
    transition: opacity .3s ease .2s;
  }

  @include mobile-only {
    display: flex;
    flex-flow: row wrap;
    gap: 0;
    justify-content: flex-start;
  }
}

.object-annotation-overview__annotation-image {
  margin-left: .75rem;

  &.placeholder {
    align-items: center;
    display: flex;
    justify-content: center;

    p {
      padding: 1rem;
      text-align: center;
    }
  }

  .object-frame {
    background-color: $color-brown-ultralight;
  }

  @include mobile-only {
    flex: 0 1 55%;
    margin-left: 0;
    margin-right: 15%;
    order: 1;
  }
}

.object-annotation-overview__annotation-caption {
  &.richtext p {
    margin-top: 0;
  }

  @include mobile-only {
    flex-grow: 1;
    margin-left: 15%;
    margin-top: .5rem;
    order: 3;
  }
}

.object-annotation-overview__page {
  @include mobile-only {
    flex: 0 1 15%;
    order: 2;
  }
}

.object-annotation-overview__link {
  align-items: flex-start;
  cursor: initial;
  flex-grow: 1;
  margin-top: 0;
  pointer-events: none;
}

.object-annotation-overview__filter-tags {
  margin: -10px -10px 15px;

  > .tag {
    margin: 10px 0 0 10px;
  }
}

.object-annotation-overview__annotation-toggle-button {
  justify-self: center;
}
