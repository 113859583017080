
.toggle-field {
  $height: 48px;

  @include typography(button);

  .toggle-field__input {
    display: none;
  }

  .toggle-field__label {
    align-items: center;
    background-color: transparent;
    border: 1px solid $color-grey-medium;
    border-radius: 24px;
    cursor: pointer;
    display: inline-flex;
    height: $height;
    padding-left: calc(#{$height} + 5px);
    padding-right: 15px;
    position: relative;
    transition: all $easing-change;

    .toggle-field__icon--off {
      visibility: visible;
    }

    .toggle-field__icon--on {
      visibility: hidden;
    }

    .toggle-field__text-off {
      display: unset;
    }

    .toggle-field__text-on {
      display: none;
    }

    .toggle-field__input:checked + & {
      background-color: $color-white;
      border-color: $color-white;
      padding-left: 15px;
      padding-right: calc(#{$height} + 5px);

      .toggle-field__icon-off,
      .toggle-field__icon-on, {
        $offset: $height - 1px;

        background-color: $color-white;
        left: calc(100% - #{$offset});
      }

      .toggle-field__icon--off {
        visibility: hidden;
      }

      .toggle-field__icon--on {
        visibility: visible;
      }

      .toggle-field__text-off {
        display: none;
      }

      .toggle-field__text-on {
        display: unset;
      }
    }

    &:hover {
      border-color: $color-teal;
    }
  }

  .toggle-field__icon-off,
  .toggle-field__icon-on {
    svg {
      height: 20px;
      transition: transform $easing-change;
      width: 20px;
    }

    background-color: transparent;
    border: 1px solid $color-grey-medium;
    border-radius: 24px;
    display: grid;
    height: $height;
    left: -1px;
    place-items: center;
    position: absolute;
    top: -1px;
    transform: scale(.85);
    transition: all $easing-change;
    width: $height;

    .toggle-field__label:hover & {
      svg {
        transform: scale(.85);
      }

      border-color: $color-teal;
      transform: scale(1);
    }
  }

  .toggle-field__text-off,
  .toggle-field__text-on {
    position: relative;
    top: 1px;
  }
}
