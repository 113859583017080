.footer-bar {
  background-color: $color-black;
  // margin-top: var(--block-margin);
  padding: 1.5rem 0;
  position: relative;

  .footer-bar__container {
    color: $color-white;
    padding: var(--component-margin) 0;
  }

  .footer-bar__grid {
    @include grid-container;

    grid-gap: 2rem 1rem;

    @include portrait {
      row-gap: .75rem var(--grid-gutter);
    }
  }

  .footer-bar__section-header {
    @include typography(heading-6);

    margin-bottom: .5rem;
  }

  .footer-bar__links,
  .footer-bar__location,
  .footer-bar__contact {
    grid-column: span 2;

    @include portrait {
      grid-column: span 3;
    }

    span {
      @include typography(body-large-sans);

      display: block;
      margin-bottom: .25rem;

      a {
        color: $color-white;
        text-decoration: underline;
        text-underline-position: under;
        transition: color $easing-change;

        &:focus,
        &:hover {
          color: $color-pink-light;
        }
      }
    }
  }

  .footer-bar__links {
    list-style: none;
    padding: 0;

    li {
      margin-bottom: .5rem;
      margin-left: 0;
      padding-left: 0;
    }

    .link {
      transition: color $easing-change;

      &:focus,
      &:hover {
        color: $color-pink-light;
      }
    }

    .link__icon {
      position: relative;
      top: 1px;
    }
  }

  .footer-bar__social {
    display: flex;
    flex-direction: column;
    grid-column: column 1 / span 2;

    @include portrait {
      grid-column: column 10 / span 3;
    }

    ul {
      display: flex;
      list-style: none;
      padding: 0;
    }

    .icon-button {
      height: 20px;
      margin-right: 12px;
      padding: 0;
      width: 20px;
    }
  }

  .footer-bar__social-item {
    @include mobile-only {
      padding-left: .3125rem;
      padding-right: .3125rem;
    }
  }

  .footer-bar__copyright {
    @include typography(body-large-sans);

    @include mobile-only {
      grid-column: column 1 / span 2;
    }

    @include portrait {
      grid-column: column 10 / span 3;
    }
  }
}
