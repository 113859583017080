
.project-section {
  position: relative;

  .project-section__grid-container {
    @include grid-container;
  }

  .project-section__title {
    @include typography(heading-2);

    padding: 0 var(--grid-page-padding);

    @include portrait {
      width: 75%;
    }

    @include landscape {
      width: 50%;
    }
  }

  .project-section__description-wrapper {
    @include typography(body-large);

    margin-top: .5rem;
    padding: 0 var(--grid-page-padding);

    @include portrait {
      padding: 0;
      padding-left: var(--grid-page-padding);
    }

    @include portrait {
      width: 75%;
    }

    @include landscape {
      width: 50%;
    }
  }

  .project-section__feature-card-grid {
    margin-bottom: calc(var(--block-margin) * .75);
    margin-top: calc(var(--block-margin) * .5 - var(--grid-spacing));
  }
}
